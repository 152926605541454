import React from 'react'
import styled from '@emotion/styled'
import { mq } from 'styles'

const Wrapper = styled.div<{
  className?: string,
  left?: boolean,
  right?: boolean,
  top?: boolean,
  bottom?: boolean
}>`
  border: 1px solid currentcolor;
  padding: var(--path-width);
  border-radius: calc(var(--path-width) * 2);
  position: relative;
  transform-style: preserve-3d;
  ${ mq.mediumAndBelow } {
    border-radius: calc(var(--path-width) * 1.5);
  }
  > div.inner-track {
    width: 100%;
    height: 100%;
    min-height: 20px;
    border-radius: var(--path-width);
    border: 1px solid currentcolor;
    ${ mq.mediumAndBelow } {
      border-radius: calc(var(--path-width) * .75);
    }
  }
  ${ ({ top }) => top === false && `
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding-top: 0;
    border-top: none;
    > div.inner-track {
      border-top: none;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  ` }
  ${ ({ right }) => right === false && `
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding-right: 0;
    border-right: none;
    > div.inner-track {
      border-right: none;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  ` }
  ${ ({ bottom }) => bottom === false && `
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-bottom: 0;
    border-bottom: none;
    > div.inner-track {
      border-bottom: none;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  ` }
  ${ ({ left }) => left === false && `
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding-left: 0;
    border-left-width: 0;
    > div.inner-track {
      border-left: none;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  ` }
`

const ChildContent = styled.div<{ xPos: string, yPos: string }>`
  position: absolute;
  ${ ({ yPos }) => yPos === 'bottom' ? `
    bottom: 0;
  ` : `
    top: 0;
  ` };
  ${ ({ xPos }) => xPos === 'left' ? `
    left: 0;
  ` : `
    right: 0;
  ` };
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--path-width);
`

const PathContent = styled.div<{ xPos: string, yPos: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--path-width);
`

type NoomPathProps = {
  className?: string,
  left?: boolean
  right?: boolean
  top?: boolean
  bottom?: boolean,
  children?: any,
  label?: any
}

const NoomPath = ({ className = '', label, left = true, right = true, top = true, bottom = true, children }: NoomPathProps) => (
  <Wrapper
    className={className}
    left={left}
    right={right}
    top={top}
    bottom={bottom}
  >
    <div className='inner-track'/>
    {label && (
      <PathContent
        className='path-label'
        xPos={left ? 'right' : 'left'}
        yPos={bottom ? 'bottom' : 'top'}
      >
        {label}
      </PathContent>
    )}
    {children && (
      <ChildContent
        className='child-content'
        xPos={left ? 'right' : 'left'}
        yPos={bottom ? 'bottom' : 'top'}
      >
        {children}
      </ChildContent>
    )}
  </Wrapper>
)

export default NoomPath