import React from 'react'
import styled from '@emotion/styled'
import { getFileAsset } from '@sanity/asset-utils'
import ReactPlayer from 'react-player/file'

const sanityOptions = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
}

const Wrapper = styled.div<{ className?: string }>`
  width: 100%;
  > div,
  video {
    width: 100% !important;
    display: block;
  }
`

type VideoProps = {
  className?: string,
  video: any,
  cover?: string,
  playing?: boolean,
  autoplay?: boolean,
  loop?: boolean,
  muted?: boolean,
  controls?: boolean,
  id?: string
}

const Video = ({
  className = '',
  video,
  cover,
  playing = true,
  autoplay = true,
  loop = true,
  muted = true,
  controls = false,
  id = ''
}: VideoProps) => {
  if (!video.asset._ref) {
    return null
  }
  const videoUrl = getFileAsset(video?.asset?._ref, sanityOptions).url
  return (
    <Wrapper className={'video-wrapper ' + className}>
      <ReactPlayer
        id={id}
        url={videoUrl}
        cover={cover}
        playing={playing}
        loop={loop}
        muted={muted}
        autoPlay={autoplay}
        controls={controls}
        width='100%'
        height='100%'
      />
    </Wrapper>
  )
}

export default Video