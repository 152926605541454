import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import imageUrlBuilder from '@sanity/image-url'
import { getImageDimensions, getImage } from '@sanity/asset-utils'
import { useInView } from 'react-intersection-observer'
import { animations } from 'styles'
import { AppContext } from 'state/AppState'

const sanityOptions = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
}

const Wrapper = styled.div<{ className?: string, inView?: boolean, ref: any }>`
  position: relative;
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    will-change: opacity;
    transition: opacity ${ animations.slowSpeed } ease-in-out;
    ${ ({ inView }) => inView ? `
      opacity: 1;
    ` : `
      opacity: 0;
    ` }
  }
`

const SetAspectRatio = styled.div<{ aspectRatio?: number }>`
  width: 100%;
  padding-bottom: ${ ({ aspectRatio }) => aspectRatio ? aspectRatio * 100 : 100 }%;
`

type ImageProps = {
  className?: string,
  image?: any,
  sizes?: string,
  quality?: number,
  loading?: 'lazy' | 'eager',
  altText?: string
}

const Image = ({ altText, className = 'image', image, sizes, quality = 80, loading = 'lazy' }: ImageProps) => {
  const { sanity } = useContext(AppContext)
  const [imgReady, setImgReady] = useState(false)
  const builder = imageUrlBuilder(sanity)
  const urlFor = (source: any) => {
    return builder.image(source)
  }
  const imageUrl = urlFor(image).url()

  const [ref, inView] = useInView({ triggerOnce: true })
  if (!imageUrl || imageUrl == null) {
    return <></>
  }

  const aspectRatio = getImageDimensions(image).height / getImageDimensions(image).width

  return (
    <Wrapper className={'image-wrapper ' + className} ref={ref} inView={loading === 'eager' || inView && imgReady}>
      <SetAspectRatio
        aspectRatio={aspectRatio}
        className='ratio-setter'
      />
      <img
        sizes={sizes || '100vw'}
        src={imageUrl}
        onLoad={() => setImgReady(true)}
        width={getImageDimensions(image).width}
        height={getImageDimensions(image).height}
        srcSet={urlFor(image).width(320).quality(quality) + ' 320w, ' + 
                urlFor(image).width(654).quality(quality) + ' 654w, ' + 
                urlFor(image).width(768).quality(quality) + ' 768w, ' + 
                urlFor(image).width(1024).quality(quality) + ' 1024w, ' + 
                urlFor(image).width(1366).quality(quality) + ' 1366w, ' + 
                urlFor(image).width(1500).quality(quality) + ' 1500w'}
        loading={loading}
        alt={image?.altText || altText || null}
      />
    </Wrapper>
  )
}

export default Image