import { lighten, darken, rgba } from 'polished'

interface colorData {
  [x: string]: any
}

const colors: colorData = {
	// Core Colors
	black: '#191717',
	white: '#fff',
	offWhite: '#F6F4EE',
	taracco: '#FB513B',
	gold: '#B39154',
	
	// Accent Colors
	sand: '#E4DECE',
	sand2: '#D3C9B4',
	kale: '#316700',
	pine: '#2B4010',
	berry: '#A82F67',
	plum: '#671D48',
	blueberry: '#1D3A44',
	cinnamon: '#AA3521',
	cherry: '#5F110E',
	lagoon: '#05727A',

	// Simple Names
	yellow: '#B39154',
	green: '#316700',
	red: '#FB513B',

	// Basic UI Colors
	transparent: 'transparent',
	currentcolor: 'currentcolor',
	bgColor: '#F6F4EE',
	mainColor: '#FB513B',
	alert: '#FB513B',
	notify: '#B39154',
	success: '#316700',
	textColor: '#1B1718',
	lightTextColor: rgba('#1B1718', 0.4),
	lightGrey: '#E4DECE',
	hrColor: '#E4DECE',

	// Color Variations
	mainColorDarken: darken(0.07, '#FB513B'),
	mainColorLighten: lighten(0.07, '#FB513B'),
}


export default colors