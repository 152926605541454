import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Video from 'components/Video'
import { mq } from 'styles'

const StyledVideo = styled(Video)<{ ratio?: any }>`
	${ ({ ratio }) => ratio ? `
		${ mq.largerAndUp } {
			height: 100%;
			width: ${ `calc(var(--media-height) * ${ ratio })` };
		}
	` : `` }
`

type VerticalVideoProps = {
  video?: any,
  sectionKey?: any
}

const VerticalVideo = ({
	video,
	sectionKey
}: VerticalVideoProps) => {
	const [ratio, setRatio] = useState<any>(null)

	useEffect(() => {
		const videoItem = document.querySelector('#v-video-' + sectionKey + ' video') as HTMLVideoElement | null
		if (videoItem !== null && videoItem instanceof HTMLElement) {
			videoItem.addEventListener( "loadedmetadata", function (e) {
				const width = this.videoWidth
				const height = this.videoHeight
				const videoRatio = width / height
				console.log(videoRatio)
				setRatio(videoRatio)
			}, false )
		}
	}, [])

	return (
		<StyledVideo video={video} ratio={ratio} id={'v-video-' + sectionKey} />
	)
}

export default VerticalVideo