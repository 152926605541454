import React from 'react'
import styled from '@emotion/styled'
import Section from 'components/Section'
import Grid from 'components/Grid'
import Media from 'components/Media'
import SanityRichText from 'components/SanityRichText'
import { colors } from 'styles'
import { MdCheck, MdClose } from 'react-icons/md'

const Wrapper = styled(Grid)``

const ItemImageWrapper = styled.div<{ type?: string }>`
	position: relative;
	.media-wrapper {
		width: 100%;
		.image-wrapper,
		.video-wrapper {
			width: 100%;
			img, video {
				width: 100%;
				max-width: none;
			}
		}
	}
`

const ItemOverlay = styled.div<{ type?: string }>`
	--color: ${ colors.sand };
	${ ({ type }) => type === 'do' && `
		--color: ${ colors.kale };
	` }
	${ ({ type }) => type === 'dont' && `
		--color: ${ colors.red };
		height: 100%;
	` }
	top: 0;
	left: 0;
	width: 100%;
	position: absolute;
	z-index: 2;
`

const ItemIcon = styled.div`
	top: 0;
	left: 0;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	background: var(--color);
	color: ${ colors.white };
	margin: var(--site-gutters);
	svg {
		display: block;
	}
`

const ItemColorBar = styled.div`
	width: 100%;
	background: var(--color);
	height: 4px;
`

const StrikeThrough = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(to left top, transparent calc(50% - 3px), var(--color) calc(50% - 3px), var(--color) calc(50% + 1px), transparent calc(50% + 1px));
`

interface gridData {
  [x: string]: any
}

const getGridSettings: gridData = {
	four: {
		medium: '1 [6] [6] 1',
		large: '1 [3] [3] [3] [3] 1'
	},
	three: {
		medium: '1 [6] [6] 1',
		large: '1 [4] [4] [4] 1'
	},
	two: {
		medium: '1 [6] [6] 1',
		large: '1 [6] [6] 1'
	},
}

type MediaGridProps = {
  className?: string,
  columns: string,
  title?: string,
  items: Array<object>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const MediaGrid = ({
	className,
	columns,
	items,
	title,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: MediaGridProps) => {
	if (items?.length < 1) {
		return null
	}
	
	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			{title && <Grid small='[1]' medium='1 [12] 1' margins><h6 style={{ margin: '0 0 var(--site-gutters) 0' }}>{title}</h6></Grid>}
			<Wrapper
				className={className}
				small='[1]'
				medium={getGridSettings[columns].medium}
				large={getGridSettings[columns].large}
				colGap='var(--site-gutters)'
				rowGap='calc(var(--vertical-spacing) * 1)'
				vAlign='top'
				margins
			>	
				{items.map((item: any) => (
					<div key={item._key}>
						<ItemImageWrapper>
							{item.type !== 'none' && (
								<ItemOverlay type={item.type}>
									<ItemColorBar />
									{item.type === 'do' && (<ItemIcon><MdCheck size={24} /></ItemIcon>)}
									{item.type === 'dont' && (<StrikeThrough/>)}
								</ItemOverlay>
							)}
							<Media media={item.media}/>
						</ItemImageWrapper>
						<div>
							{item?.title && (<h6 style={{ margin: 'var(--site-gutters) 0 -.5em 0' }}>{item.title}</h6>)}
							{item?.caption && Array.isArray(item?.caption) && (<div style={{ margin: 'var(--site-gutters) 0 0 0' }}><SanityRichText text={item.caption}/></div>)}
						</div>
					</div>
				))}
			</Wrapper>
		</Section>
	)
}

export default MediaGrid
