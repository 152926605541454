import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { getFileAsset } from '@sanity/asset-utils'
import Grid from 'components/Grid'
import Section from 'components/Section'
import SanityRichText from 'components/SanityRichText'
import Button from 'components/Button'
import StarHeadline from 'components/StarHeadline'
import { typography, colors, mq } from 'styles'

const sanityOptions = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
}

const ModuleSection = styled(Section)`
	padding-top: calc(var(--vertical-spacing) / 2);
	margin-bottom: calc(var(--vertical-spacing) / 2);
`

const Wrapper = styled(Grid)``

const Headline = styled.h3<{size: string}>`
	margin-top: 0;
	${ ({ size }) => size === 'large' ? typography.h3 : typography.h4 }
`

const MainText = styled.div`
	p {
		${ typography.bodyMedium }
	}
`

const SideNote = styled.div`
	background: ${ colors.sand };
	padding: 30px;
	p {
		margin-bottom: 0;
	}
`

const ButtonLinks = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	.button-wrap {
		flex-shrink: 0;
		margin-bottom: 10px;
		&:not(:last-of-type) {
			margin-right: 10px;
		}
		&:last-of-type {
			margin-right: 10px;
			padding-right: var(--site-margins);
			${ mq.mediumAndUp } {
				padding-right: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			}
		}	
	}
`

interface objData {
  [x: string]: any
}

interface linkItem {
	title: string,
	slug: string,
	_id: string,
}

type TextSectionProps = {
  className?: string,
  headline: string,
  headlineSize: string,
  text?: string,
  links?: Array<linkItem>,
  secondaryContent?: any,
  aside?: any,
  _key?: string,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean,
	navTitle?: string,
	sectionDivider?: boolean
}

const TextSection = ({
	className,
	headline,
	headlineSize,
	text,
	links,
	secondaryContent,
	aside,
	_key,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection,
	navTitle,
	sectionDivider
}: TextSectionProps) => {
	console.log(text)
	const hasAside = aside?.asideText || aside?.asideHeadline
	return (
		<ModuleSection
			id={_key}
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			{navTitle && (
				<Grid
					small='[1]'
					medium='1 [12] 1'
					colGap='var(--site-gutters)'
					rowGap='var(--site-gutters)'
					margins
				>
					<StarHeadline border={sectionDivider}>{navTitle}</StarHeadline>
				</Grid>
			)}

			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				large='2 [16] 1 [7] 2'
				larger='1 [8] 1 [3] 1'
				vAlign='top'
				colGap='var(--site-gutters)'
				rowGap='var(--site-gutters)'
				margins
			>
				<div>
					{headline && (<Headline size={headlineSize}>{headline}</Headline>)}
					{text && Array.isArray(text) && (<MainText><SanityRichText text={text}/></MainText>)}
					{links && links?.length > 0 && 
						<ButtonLinks>
							{links?.map((link: any) => {
								let fileUrl = null
								if (link?.linkType === 'file') {
									fileUrl = getFileAsset(link?.file?.asset?._ref, sanityOptions).url
								}
								const linkProps: objData = {
									external: {
										href: link.url,
										as: 'a',
										target: '_blank',
										rel: 'noopener noreferrer'
									},
									page: {
										as: Link,
										to: '/' + link?.page?.slug?.current
									},
									file: {
										href: fileUrl,
										as: 'a',
										target: '_blank',
										rel: 'noopener noreferrer'
									}
								}
								return (
									<div className='button-wrap'>
										<Button
											key={link._key}
											size='tiny'
											setTheme='sand'
											{...linkProps[link.linkType]}
										>{link.title}</Button>
									</div>
								)
							})}
						</ButtonLinks>
					}

					{secondaryContent?.secondaryHeadline && (<h6 style={{ marginTop: '4em' }}>{secondaryContent.secondaryHeadline}</h6>)}
					{secondaryContent?.secondaryText && (<SanityRichText text={secondaryContent.secondaryText}/>)}
				</div>

				{hasAside && (
					<SideNote>
						{aside?.asideHeadline && (<h6>{aside.asideHeadline}</h6>)}
						{aside?.asideText && (<p>{aside.asideText}</p>)}
					</SideNote>
				)}
			</Wrapper>
		</ModuleSection>
	)
}

export default TextSection
