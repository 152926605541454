import React, { useEffect, useRef, useState } from 'react'
import lottie from 'lottie-web'
import styled from '@emotion/styled'
import { Stickyroll } from '@stickyroll/stickyroll'
import { useInView } from 'react-intersection-observer'
import ThemeSelector from 'components/ThemeSelector'
import Grid from 'components/Grid'
import { colors, typography, mq } from 'styles'
import tagCloudJson from 'assets/1-noom-tag-cloud-nobg.json'
import posterJson from 'assets/2-noom-brand-system.json'

const Wrapper = styled(ThemeSelector)`
`

const AnimationMeta = styled.div<{ hide?: boolean }>`
	position: absolute;
	bottom: var(--site-margins);
	left: var(--site-margins);
	max-width: 340px;
	z-index: 2;
	opacity: ${ ({ hide }) => hide ? 0 : 1 };
	transition: opacity .4s ease-in-out;
	h6 {
		margin: 0;
		${ typography.smallCaps }
		color: ${ colors.gold };
		margin-bottom: .25em;
	}
	p {
		margin: 0;
		${ typography.bodySmall }
	}
`

const AnimatedContent = styled.div`
	height: 100vh;
	overflow: hidden;
`

const LottieAnimation = styled.div<{ ref?: any, cover?: boolean }>`
	height: 100%;
	position: relative;
	z-index: 1;
	${ ({ cover }) => cover ? `
		${ mq.largeAndUp } {
			// svg {
			// 	position: absolute;
			// 	top: 50%;
			// 	left: 50%;
			// 	transform: translate(-50%, -50%) !important;
			// 	width: auto !important;
			// 	@media (min-aspect-ratio: 16/9) {
			// 	  width: 102% !important;
			// 	  height: auto !important;
			// 	}
			// }
		}
		${ mq.mediumAndBelow } {
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) !important;
				width: 150% !important;
				height: auto !important;
			}
		}
	` : `
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 100%;
		max-height: 60vh;
		transform: translate(0, -50%) !important;
	` }
`

type LottieScrollProps = {
  className?: string,
  type: string,
  captionTitle?: string,
  caption?: string,
}

interface objData {
  [x: string]: any
}

const animations: objData = {
	poster: posterJson,
	tags: tagCloudJson
}

const LottieScroll = ({
	className = '',
	type,
	captionTitle,
	caption
}: LottieScrollProps) => {
	const animationContainer = useRef(null)
	const [progress, setProgress] = useState(0)
	const [totalFrames, setTotalFrames] = useState(0)
	const [animationEl, setAnimationEl] = useState<any>()
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '200px'
	})

	useEffect(() => {
		if (inView) {
	    const anim = lottie.loadAnimation({
	      container: animationContainer.current!,
	      renderer: 'svg',
	      loop: false,
	      autoplay: false,
	      animationData: animations[type] || tagCloudJson
	    })
	    setAnimationEl(anim)
	    console.log('inView')
  	}
  }, [inView])

  useEffect(() => {
  	if (animationEl) {
	    const maxFrames = animationEl.totalFrames
		  let frame = Math.round(maxFrames * progress)
		  if (frame < 1) {
		  	frame = 1
		  }
		  if (frame >= animationEl.totalFrames) {
		  	frame = animationEl.totalFrames
		  }

		  animationEl.goToAndStop(frame, true)
		}
  }, [progress])

  const setCurrentProgress = (progress: number) => {
  	setProgress(progress)
  }

	return (
		<Stickyroll pages={['one']} factor={animationEl?.totalFrames / 15}>
			{({ progress }) => {
				setCurrentProgress(progress)
				return (
					<div ref={ref}>
						<Wrapper setTheme='sand'>
							<AnimatedContent>
								<LottieAnimation ref={animationContainer} cover={type === 'poster'} />
								<AnimationMeta hide={progress > .9}>
									<h6>{captionTitle}</h6>
									<p>{caption}</p>
								</AnimationMeta>
							</AnimatedContent>
						</Wrapper>
					</div>
				)
			}}
		</Stickyroll>
	)
}

export default LottieScroll
