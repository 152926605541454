import React, { useContext } from 'react'
import { darken } from 'polished'
import * as Scroll from 'react-scroll'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Grid from 'components/Grid'
import Logo from 'components/Logo'
import { AppContext } from 'state/AppState'
import { ReactComponent as ArrowUp } from 'assets/images/arrow-stem-up.svg'
import { typography, mq, colors, animations } from 'styles'

const FooterSection = styled.footer`
  position: relative;
  ${ mq.mediumAndUp } {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }
`

const Wrapper = styled(Grid)<{ className?: string }>`
  padding: var(--site-margins);
  p {
    margin: 0;
  }
`

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${ typography.body }
  li {
    a {
      transition: opacity .3s ease-in-out;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: currentcolor;
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform .3s ease-in-out;
      }
      &:hover,
      &[disabled] {
        &:after {
          transform-origin: left center;
          transform: scaleX(1);
        }
      }
    }
  }
`

const UpButtonWrapper = styled.div`
  text-align: right;
  ${ mq.largeAndBelow } {
    position: absolute;
    top: var(--site-margins);
    right: var(--site-margins);
  }
`

const FooterTopButton = styled.button`
  appearance: none;
  text-align: inherit;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  box-shadow: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  color: inherit;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${ colors.sand };
  color: ${ colors.textColor };
  overflow: hidden;
  transition: background ${ animations.mediumSpeed } ease-in-out;
  &:hover {
    background: ${ darken(0.07, colors.sand) };
    svg {
      transition: transform ${ animations.mediumSpeed } ease-in-out;
      transform: translate3d(0, -100%, 0);
    }
  }
  svg {
    display: block;
    height: 48px;
    flex-shrink: 0;
    * {
      fill: currentcolor;
    }
  }
`

type FooterProps = {
  className?: string
}

const scroll = Scroll.animateScroll

const Footer = ({ className }: FooterProps) => {
  const { settings } = useContext(AppContext)
  const { companyLinks, socialLinks, contactEmail } = settings
  return (
    <FooterSection>
      <Wrapper
        className={className}
        small='[1]'
        // medium='[7] [4] 1 [2]'
        large='[7] [4] 1 [2]'
        colGap='var(--site-gutters)'
        margins
        vAlign='top'
        rowGap='40px'
      >
        <Grid
          small='[1]'
          medium='[1] [2]'
          large='[2] [5]'
          colGap='var(--site-gutters)'
          vAlign='top'
          rowGap='40px'
        >
          <div>
            <LinkList>
              {companyLinks && companyLinks.map((link: any) => (
                <li key={link._key}>
                  <a href={link.url} target='_blank' rel='noopener noreferrer'>{link.title}</a>
                </li>
              ))}
            </LinkList>
          </div>
          <div>
            <LinkList>
              {socialLinks && socialLinks.map((link: any) => (
                <li key={link._key}>
                  <a href={link.url} target='_blank' rel='noopener noreferrer'>{link.title}</a>
                </li>
              ))}
            </LinkList>
          </div>
        </Grid>
        <div>
          <p>{contactEmail && (<>Have questions? <a href={'mailto:' + contactEmail}>Email us</a>.<br/></>)}</p>
        </div>
        <UpButtonWrapper>
          <FooterTopButton onClick={() => scroll.scrollToTop()}>
            <ArrowUp />
            <ArrowUp />
          </FooterTopButton>
        </UpButtonWrapper>
      </Wrapper>
      <Wrapper
        className={className}
        small='[1]'
        large='[1] [1]'
        colGap='var(--site-gutters)'
        rowGap='5px'
        margins
        vAlign='top'
      >
        <div><Logo /></div>
        <div>
          <p>Copyright {new Date().getFullYear()} Noom, Inc. All Rights Reserved</p>
        </div>
      </Wrapper>
    </FooterSection>
  )
}

export default Footer