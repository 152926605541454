import * as util from './util'

export const verticalSpacing = (attr = 'padding-top', multiple = 1) => `
	${ util.responsiveStyles(attr, (140 * multiple), (100 * multiple), (90 * multiple), (44 * multiple)) }
`

export const baseBorderRadius = 4

interface sizeData {
  [x: string]: any
}

// Buttons, Inputs, Selects, etc.
export const uiElementSizes: sizeData = {
	tiny: 32,
	small: 40,
	medium: 50,
	large: 60,
	huge: 100
}

export const responsiveUiSizes: sizeData = {
	small: 1,
	medium: 1,
	large: 1,
	huge: 1
}
