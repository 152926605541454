import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { typography, animations, colors, util, mq } from 'styles'

const Wrapper = styled.div<{ className?: string, chartSize?: 'small' | 'large' }>`
  --accent-color: ${ colors.gold };
  ${ ({ chartSize }) => chartSize === 'small' ? `
    --y-axis-width: 22px;
    --x-axis-height: 24px;
    ${ util.responsiveStyles('--row-height', 50, 44, 40, 40) }
  ` : `
    ${ util.responsiveStyles('--y-axis-width', 30, 30, 24, 20) }
    ${ util.responsiveStyles('--x-axis-height', 30, 30, 26, 24) }
    ${ util.responsiveStyles('--row-height', 70, 70, 60, 50) }
  ` }
  position: relative;
`

const ChartColumns = styled.div<{ chartSize?: 'small' | 'large' }>`
  position: absolute;
  top: 0;
  left: var(--y-axis-width);
  right: 0;
  bottom: var(--x-axis-height);
  display: flex;
  align-items: stretch;
  ${ ({ chartSize }) => chartSize === 'small' && `
    > div {
      &:first-of-type {
        margin-left: -8%;
      }
      &:last-of-type {
        margin-right: -8%;
      }
    }
  ` }
  ${ mq.mediumAndBelow } {
    > div {
      &:first-of-type {
        margin-left: -8%;
      }
      &:last-of-type {
        margin-right: -8%;
      }
    }
  }
`

const Column = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: stretch;
`

const ColLine = styled.div`
  width: 1px;
  background: var(--text-color);
  position: relative;
`

const ValueMarker = styled.div<{ chartSize?: 'small' | 'large', value: number }>`
  --marker-size: ${ ({ chartSize }) => chartSize === 'small' ? '11px' : '17px'};
  ${ mq.largeAndBelow } {
    --marker-size: ${ ({ chartSize }) => chartSize === 'small' ? '11px' : '15px'};
  }
  ${ mq.mediumAndBelow } {
    --marker-size: 11px;
  }
  transition: transform ${ animations.mediumSpeed } ease-in-out;
  position: absolute;
  left: calc((var(--marker-size) - 1px) / -2);
  height: var(--row-height);
  display: flex;
  align-items: center;
  justify-content: center;
  ${ ({ value }) => `
    transform: translateY(${ 100 * (5 - value)}%);
  ` }
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: var(--marker-size);
    height: var(--marker-size);
    background: ${ ({ chartSize }) => chartSize === 'small' ? 'var(--text-color)' : colors.red};
  }
`

const ColTitle = styled.div<{ chartSize?: 'small' | 'large' }>`
  ${ typography.smallCaps }
  ${ ({ chartSize }) => chartSize === 'small' ? `
    ${ util.responsiveStyles('font-size', 10, 10, 8, 10) }
  ` : `
    ${ util.responsiveStyles('font-size', 12, 12, 11, 10) }
  ` }
  color: var(--accent-color);
  height: var(--x-axis-height);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 25%;
  flex-grow: 1;
`

const ColY = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  top: 0;
  left: 0;
`

const YVal = styled.div<{ chartSize?: 'small' | 'large' }>`
  ${ typography.smallCaps }
  ${ ({ chartSize }) => chartSize === 'small' ? `
    ${ util.responsiveStyles('font-size', 12, 12, 12, 12) }
  ` : `
    ${ util.responsiveStyles('font-size', 16, 16, 14, 12) }
  ` }
  color: var(--accent-color);
  height: var(--row-height);
  display: flex;
  align-items: center;
`

const HLine = styled.div`
  ${ typography.smallCaps }
  border-top: 1px dashed var(--text-color);
  flex-grow: 1;
`

const XAxis = styled.div<{ chartSize?: 'small' | 'large' }>`
  display: flex;
  width: 100%;
  padding-left: var(--y-axis-width);
  ${ ({ chartSize }) => chartSize === 'small' && `
    > div {
      &:first-of-type {
        margin-left: -8%;
      }
      &:last-of-type {
        margin-right: -8%;
      }
    }
  ` }
  ${ mq.mediumAndBelow } {
    > div {
      &:first-of-type {
        margin-left: -8%;
      }
      &:last-of-type {
        margin-right: -8%;
      }
    }
  }
`

type LevelsChartProps = {
  className?: string,
  chartSize?: 'small' | 'large',
  valueOne: number,
  valueTwo: number,
  valueThree: number,
  valueFour: number
}

const capValue = (value: number) => {
  if (value > 5) {
    return 5
  } else if (value < 1) {
    return 1
  } else {
    return value
  }
}

const LevelsChart = ({
  className,
  chartSize = 'large',
  valueOne = 1,
  valueTwo = 1,
  valueThree = 1,
  valueFour = 1
}: LevelsChartProps) => (
  <Wrapper className={className} chartSize={chartSize}>
    <ColY>
      <YVal chartSize={chartSize}>
        <span style={{ display: 'block', width: 'var(--y-axis-width)' }}>5</span><HLine/>
      </YVal>
      <YVal chartSize={chartSize}>
        <span style={{ display: 'block', width: 'var(--y-axis-width)' }}>4</span><HLine/>
      </YVal>
      <YVal chartSize={chartSize}>
        <span style={{ display: 'block', width: 'var(--y-axis-width)' }}>3</span><HLine/>
      </YVal>
      <YVal chartSize={chartSize}>
        <span style={{ display: 'block', width: 'var(--y-axis-width)' }}>2</span><HLine/>
      </YVal>
      <YVal chartSize={chartSize}>
        <span style={{ display: 'block', width: 'var(--y-axis-width)' }}>1</span><HLine/>
      </YVal>
    </ColY>
    <ChartColumns chartSize={chartSize}>
      <Column>
        <ColLine>
          <ValueMarker chartSize={chartSize} value={capValue(valueOne)} />
        </ColLine>
      </Column>
      <Column>
        <ColLine>
          <ValueMarker chartSize={chartSize} value={capValue(valueTwo)} />
        </ColLine>
      </Column>
      <Column>
        <ColLine>
          <ValueMarker chartSize={chartSize} value={capValue(valueThree)} />
        </ColLine>
      </Column>
      <Column>
        <ColLine>
          <ValueMarker chartSize={chartSize} value={capValue(valueFour)} />
        </ColLine>
      </Column>
    </ChartColumns>
    <XAxis chartSize={chartSize}>
      <ColTitle chartSize={chartSize}>Knowing</ColTitle>
      <ColTitle chartSize={chartSize}>Supportive</ColTitle>
      <ColTitle chartSize={chartSize}>Straight Up</ColTitle>
      <ColTitle chartSize={chartSize}>Magnetic</ColTitle>
    </XAxis>
  </Wrapper>
)

export default LevelsChart