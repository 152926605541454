import React, { useRef, useState, useContext } from 'react'
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom'
import Grid from 'components/Grid'
import NoomPath from 'components/NoomPath'
import ThemeSelector from 'components/ThemeSelector'
import Image from 'components/Image'
import { AppContext } from 'state/AppState'
import * as Scroll from 'react-scroll'
import { useWindowWidth } from '@react-hook/window-size'
import { mq, colors, typography, animations } from 'styles'
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left-thick.svg'
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right-thick.svg'

const transitionSpeed = 750

const Wrapper = styled(Grid)<{ className?: string, onClick?: any }>`
  color: ${ colors.white };
  ${ mq.largeAndUp } {
    height: 50vh;
  }
  ${ mq.largeAndBelow } {
    display: flex;
    > div,
    > a {
      width: 50%;
    }
  }
  ${ mq.mediumAndBelow } {
    flex-direction: column;
    > div,
    > a {
      width: 100%;
    }
  }
  > div,
  > a {
    direction: ltr;
    height: 100%;
  }
`

const SoftFooterSection = styled(ThemeSelector)<{ onClick?: any, setTheme: string }>`
  position: relative;
  cursor: pointer;
`

const Pathways = styled.div`
  position: absolute;
  color: ${ colors.white };
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 4;
  ${ mq.mediumAndBelow } {
    --path-width: calc((var(--100vw) - (var(--site-margins) * 2) - (var(--site-gutters) * 5)) / 6);
    width: 130%;
    height: 70%;
    right: 0;
    left: auto;
  }
  ${ mq.smallAndBelow } {
    --path-width: calc((var(--100vw) - (var(--site-margins) * 2) - (var(--site-gutters) * 3)) / 4);
    width: 120%;
  }
`

const SectionLeader = styled.div<{ type?: string }>`
  ${ typography.h6 }
  line-height: 1em;
  padding: 0 var(--site-margins);
  ${ ({ type }) => type === 'back' ? `
    ${ mq.mediumAndBelow } {
      display: none;
    }
  ` : `` }
  ${ ({ type }) => type === 'back-mobile' ? `
    padding: var(--vertical-spacing) var(--site-margins);
    ${ mq.largeAndUp } {
      display: none;
    }
  ` : `` }
  svg {
    vertical-align: middle;
    margin-top: -2px;
  }
`

const ThumbnailImage = styled(Image)`
  background: #ccc;
  height: 100%;
  width: 100%;
  .ratio-setter {
    display: none;
  }
  ${ mq.mediumAndBelow } {
    padding-bottom: 100%;
    height: auto;
  }
`

const TransitionPane = styled(ThemeSelector)<{ top: number, left: number, width: number, height: number, transitioning?: any }>`
  position: fixed;
  z-index: 4;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  top: ${ ({ top }) => top }px;
  left: ${ ({ left }) => left }px;
  width: ${ ({ width }) => width }px;
  height: ${ ({ height }) => height }px;
  animation: ${ animations.introCoverScreen } ${ transitionSpeed }ms cubic-bezier(0.44, 0.24, 0.16, 1.00) forwards;
  ${ ({ transitioning }) => transitioning ? `
    animation-play-state: running;
  ` : `
    animation-play-state: paused;
  ` }
`

const TransitionImage = styled(Image)`
  background: #ccc;
  flex-grow: 1;
  height: 100%;
  .ratio-setter {
    display: none;
  }
`

const TransitionImagePusher = styled.div<{ transitioning?: any }>`
  height: 100%;
  flex-shrink: 0;
  animation: ${ animations.introImgPush } ${ transitionSpeed * .75 }ms ${ transitionSpeed * .25 }ms cubic-bezier(0.44, 0.24, 0.16, 1.00) forwards;
  ${ ({ transitioning }) => transitioning ? `
    animation-play-state: running;
  ` : `
    animation-play-state: paused;
  ` }
`

const PathwayLeft = styled(NoomPath)`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(50% - 60px);
  height: 46%;
`

const PathwayRight = styled(NoomPath)`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50% + 62px + var(--path-width));
  height: 75%;
`

const NextSectionCover = styled.div<{ to?: string, as?: any }>`
  position: relative;
  display: block;
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: black;
    left: 0;
    top: 0;
    z-index: 3;
    opacity: 0;
    transition: opacity ${ animations.mediumSpeed } ease-in-out;
  }
  &:hover {
    &:after {
      opacity: .1;
    }
  }
`

const CoverLink = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const BackLink = styled.div<{ onClick?: any, to?: string, as?: any }>`
  display: block;
  transition: background ${ animations.mediumSpeed } ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, .1);
  }
  ${ mq.largeAndUp } {
    height: 100%;
    width: 100%;
  }
`

type SoftFooterProps = {
  className?: string,
  currentPage?: string
}

interface navItemData {
  [x: string]: any
}

const SoftFooter = ({ className, currentPage }: SoftFooterProps) => {
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [imageValues, setImageValues] = useState<DOMRect>()
  const [transitioning, setTransitioning] = useState(false)
  const navigate = useNavigate()
  const { navigation } = useContext(AppContext)
  const winWidth = useWindowWidth()

  const scroll = Scroll.animateScroll

  const navItems: Array<any> = []

  navigation.forEach((item: navItemData) => {
    if (item?.items?.length > 0) {
      item.items.forEach((navItem: navItemData) => {
        navItems.push(navItem)
      })
    }
  })

  const currentIndex = navItems.findIndex((item: navItemData) => {
    return item.slug === currentPage
  })

  const nextIndex = currentIndex + 1
  const prevIndex = currentIndex - 1
  const nextPage = navItems[nextIndex]
  const prevPage = prevIndex && navItems[currentIndex - 1]
  const isHome = navItems[currentIndex]?.slug === 'home'


  if (!currentPage || !navItems[nextIndex]) {
    return null
  }

  const scaleImage = (slug?: string) => {
    if (slug) {
      setTransitioning(true)
      if (imageContainerRef !== null) {
        if (imageContainerRef.current !== null) {
          setImageValues(imageContainerRef.current.getBoundingClientRect())
        }
      }
      setTimeout(() => {
        navigate('/' + slug)
      }, transitionSpeed)
      setTimeout(() => {
        setTransitioning(false)
      }, transitionSpeed)
    }
  }

  const renderPathways = () => (
    <Pathways className='pathways'>
      <PathwayLeft
        left={false}
        top={false}
      >
        <SectionLeader type='back'><ArrowLeft />&nbsp;&nbsp;{prevPage?.title || 'Welcome'}</SectionLeader>
      </PathwayLeft>
      <PathwayRight
        right={false}
        top={false}
      >
        <SectionLeader>{nextPage.title}&nbsp;&nbsp;<ArrowRight /></SectionLeader>
      </PathwayRight>
    </Pathways>
  )

  return (
    <>
      <SoftFooterSection setTheme='gold'>
        <Wrapper
          className={className}
          small='[1]'
          large='[1] [1]'
          gridDirection='rtl'
        >
          {winWidth > 1023 ? (
            <NextSectionCover
              ref={imageContainerRef}
              onClick={() => scaleImage(nextPage.slug)}
            >
              <ThumbnailImage image={nextPage?.pageIntro?.pageThumbnail} sizes='800px' />
            </NextSectionCover>
          ) : (
            <NextSectionCover
              ref={imageContainerRef}
              to={nextPage.slug ? ('/' + nextPage.slug) : '/'}
              as={Link}
            >
              <ThumbnailImage image={nextPage?.pageIntro?.pageThumbnail} sizes='800px' />
            </NextSectionCover>
          )}
          <div>
            {!isHome ? (
              <BackLink to={prevPage?.slug ? ('/' + prevPage?.slug) : '/'} as={Link}>
                <SectionLeader type='back-mobile'><ArrowLeft />&nbsp;&nbsp;{prevPage?.title || 'Welcome'}</SectionLeader>
              </BackLink>
            ) : (
              <BackLink onClick={() => !prevPage?.slug ? scroll.scrollToTop() : null}>
                <SectionLeader type='back-mobile'><ArrowLeft />&nbsp;&nbsp;{prevPage?.title || 'Welcome'}</SectionLeader>
              </BackLink>
            )}
          </div>
        </Wrapper>
        {renderPathways()}
      </SoftFooterSection>
      
      <TransitionPane
        top={imageValues?.y || 0}
        left={imageValues?.x || 0}
        width={imageValues?.width || 0}
        height={imageValues?.height || 0}
        setTheme={nextPage?.pageIntro?.theme}
        transitioning={transitioning}
      >
        <TransitionImagePusher transitioning={transitioning} />
        <TransitionImage image={nextPage?.pageIntro?.pageThumbnail} loading='eager' />
      </TransitionPane>
      
    </>
  )
}

export default SoftFooter
