import React, { useState } from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import Section from 'components/Section'
import LevelsChart from 'components/LevelsChart'
import SanityRichText from 'components/SanityRichText'
import AnimateHeight from 'react-animate-height'
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right-thick.svg'
import { colors, mq, animations } from 'styles'

const Wrapper = styled(Grid)``

const Card = styled.div`
	padding: ${ 100 / 14 }vw;
	background: ${ colors.white };
	${ mq.mediumAndUp } {
		padding: ${ 100 / 28 }vw;
	}
	${ mq.largerAndUp } {
		padding: var(--col-width);
	}
`

const AccordionItemHeader = styled.div<{ active?: boolean }>`
	position: relative;
	overflow: hidden;
	cursor: pointer;
	h6 {
		transition: transform ${ animations.mediumSpeed } ease-in-out;
		${ ({ active }) => active ? `
			transform: translate3d(30px, 0, 0);
		` : `` } 
	}
	svg {
		pointer-events: none;
		position: absolute;
		top: 50%;
		margin-top: -8px;
		left: 0;
		transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
		${ ({ active }) => active ? `
			transform: none;
			opacity: 1;
		` : `
			transform: translate3d(-10px, 0, 0);
			opacity: 0;
		` } 
	}
`

const SystemItem = styled.div<{firstItem?: boolean, as?: any, single?: boolean }>`
	appearance: none;
	text-align: inherit;
	padding: 0;
	border: none;
	outline: none;
	background: transparent;
	box-shadow: none;
	display: block;
	width: 100%;
	cursor: pointer;
	color: inherit;
	${ ({ single }) => !single ? `
		border-bottom: 1px solid var(--hr-color);
	` : '' }
	${ ({ firstItem }) => firstItem && `
		border-top: 1px solid var(--hr-color);
	` }
	h6 {
		margin-bottom: 1em;
		${ ({ as }) => as === 'button' && `
			padding-top: 1em;
		` }
	}
	p {
		margin: 0;
		padding-bottom: 1.5em;
		max-width: 40em;
	}
`

interface itemData {
	title: string,
	text: string,
	chartValues: {
		knowing: string,
		magnetic: string,
		straightUp: string,
		supportive: string
	}
}

type NumberSystemProps = {
  className?: string,
  items: Array<itemData>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const NumberSystem = ({
	className,
	items,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: NumberSystemProps) => {
	const [currentItem, setCurrtentItem] = useState(0)
	if (!items || items.length < 1) {
		return null
	}

	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				margins
			>
				<div>
					<Grid
						small='[1]'
						large='[7] 1 [16]'
						larger='[3] 1 [8]'
						colGap='var(--site-gutters)'
						rowGap='var(--site-margins)'
					>
						<div>
							{items.length > 1 ? items.map((item: itemData, index) => (
								<SystemItem
									onClick={() => setCurrtentItem(index)}
									key={'item-' + index}
									as='button'
									firstItem={index === 0}
								>
									<AccordionItemHeader active={currentItem === index}>
										<ArrowRight/>
										<h6>{item.title}</h6>
									</AccordionItemHeader>
									{item?.text && Array.isArray(item?.text) && (
										<AnimateHeight
											duration={300}
											delay={0}
											animateOpacity={false}
											height={currentItem === index ? 'auto' : 0}
										>
											<SanityRichText text={item.text}/>
										</AnimateHeight>
									)}
								</SystemItem>
							)) : (
								<SystemItem single>
									<h6>{items[0].title}</h6>
									{items[0]?.text && Array.isArray(items[0]?.text) && (
										<SanityRichText text={items[0].text}/>
									)}
								</SystemItem>
							)}
						</div>
						<div>
							<Card>
								<LevelsChart
									valueOne={parseInt(items[currentItem].chartValues.knowing)}
									valueTwo={parseInt(items[currentItem].chartValues.supportive)}
									valueThree={parseInt(items[currentItem].chartValues.straightUp)}
									valueFour={parseInt(items[currentItem].chartValues.magnetic)}
								/>
							</Card>
						</div>
					</Grid>
				</div>
			</Wrapper>
		</Section>
	)
}

export default NumberSystem
