import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import UntitledSansWoff from '../assets/fonts/untitled-sans/untitled-sans-web-regular.woff'
import UntitledSansWoff2 from '../assets/fonts/untitled-sans/untitled-sans-web-regular.woff2'
import UntitledSansMediumWoff from '../assets/fonts/untitled-sans/untitled-sans-web-medium.woff'
import UntitledSansMediumWoff2 from '../assets/fonts/untitled-sans/untitled-sans-web-medium.woff2'
import UntitledSansBoldWoff from '../assets/fonts/untitled-sans/untitled-sans-web-bold.woff'
import UntitledSansBoldWoff2 from '../assets/fonts/untitled-sans/untitled-sans-web-bold.woff2'

import UntitledSerifWoff from '../assets/fonts/untitled-serif/untitled-serif-web-regular.woff'
import UntitledSerifWoff2 from '../assets/fonts/untitled-serif/untitled-serif-web-regular.woff2'

import BrownBoldWoff from '../assets/fonts/brown/BrownLLWeb-Bold.woff'
import BrownBoldWoff2 from '../assets/fonts/brown/BrownLLWeb-Bold.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const UntitledSans = 'Untitled Sans'
export const UntitledSansFont = fontFace(UntitledSans, UntitledSansWoff, UntitledSansWoff2)
export const UntitledSansMediumFont = fontFace(UntitledSans, UntitledSansMediumWoff, UntitledSansMediumWoff2, 600)
export const UntitledSansBoldFont = fontFace(UntitledSans, UntitledSansBoldWoff, UntitledSansBoldWoff2, 700)

export const UntitledSerif = 'Untitled Serif'
export const UntitledSerifFont = fontFace(UntitledSerif, UntitledSerifWoff, UntitledSerifWoff2)

export const Brown = 'Brown'
export const BrownBoldFont = fontFace(Brown, BrownBoldWoff, BrownBoldWoff2, 700)
