import React from 'react'
import styled from '@emotion/styled'
import { themes } from 'styles/themes'
import textureBlueberry from 'assets/images/textures/texture-blueberry.png'
import textureGold from 'assets/images/textures/texture-gold.png'
import textureCherry from 'assets/images/textures/texture-cherry.png'
import textureCinnamon from 'assets/images/textures/texture-cinnamon.png'
import textureKale from 'assets/images/textures/texture-kale.png'
import texturePine from 'assets/images/textures/texture-pine.png'
import textureLagoon from 'assets/images/textures/texture-lagoon.png'
import textureBerry from 'assets/images/textures/texture-berry.png'
import texturePlum from 'assets/images/textures/texture-plum.png'

interface themeData {
  [x: string]: any
}

const textures: themeData = { 
  blueberry: textureBlueberry,
  gold: textureGold,
  cherry: textureCherry,
  cinnamon: textureCinnamon,
  kale: textureKale,
  pine: texturePine,
  lagoon: textureLagoon,
  berry: textureBerry,
  plum: texturePlum
}

const themeObj: themeData = themes

const Wrapper = styled.div<{ className?: string, theme?: any, texture?: string }>`
  --bg-color: ${ ({ theme }) => theme.background };
  --text-color: ${ ({ theme }) => theme.color };
  --main-color: ${ ({ theme }) => theme.mainColor || 'var(--main-color)' };
  --hr-color: ${ ({ theme }) => theme.hrColor || 'var(--hr-color)' };
  background: var(--bg-color);
  color: var(--text-color);
  ${ ({ texture }) => texture ? `
    background-image: url(${ texture });
    background-size: 591px auto;
    background-position: center;
    background-repeat: repeat;
  ` : `` }
`

type ThemeSelectorProps = {
  className?: string,
  children?: any,
  setTheme?: string
}

const ThemeSelector = ({ className = '', children, setTheme = 'default' }: ThemeSelectorProps) => {
  return (
    <Wrapper className={className} theme={themeObj[setTheme]} texture={textures[setTheme]}>
      {children}
    </Wrapper>
  )
}

export default ThemeSelector