import React, { useState, useEffect } from 'react'
import sanityClient from '@sanity/client'

const client = sanityClient({
	projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
	dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
	apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
	token: process.env.REACT_APP_SANITY_TOKEN, // or leave blank for unauthenticated usage
	useCdn: true, // `false` if you want to ensure fresh data
})

interface initialStateData {
	modal?: any,
	pageTransition?: any,
	sanity?: any,
	pages?: any,
	settings?: any,
	navigation?: any,
	getPage?: any,
	toggleModal?: any,
	setPageTransition?: any,
}

const initialState: initialStateData = {
	modal: false,
	pageTransition: 'fade',
	sanity: client,
	pages: [],
	settings: [],
	navigation: [],
	getPage: slug => { },
	toggleModal: () => { },
	setPageTransition: () => { },
}

export const AppContext = React.createContext(initialState)

const AppState = ({ children }) => {
	const [state, setState] = useState(initialState)

	const toggleModal = id => {
		if (id) {
			setState({ ...state, modal: id })
		} else {
			setState({ ...state, modal: '' })
		}
	}

	const setPageTransition = type => {
		if (type && this.state.pageTransition !== type) {
			setState({ ...state, pageTransition: type })
		}
	}

	useEffect(() => {
		const query = `*[_type == "page"]{
			...,
			modules[] {
				...,
				_type == "textSection" => {
					 ...,
					 links[]{
						 ...,
						 linkType == 'page' => {
							 page->
						 }
					 }
				}
			}
		}`
		const fetchPages = client.fetch(query, {}).then(pages => pages)

		const settingsQuery = '*[_id == "siteSettings"][0]'
		const fetchSettings = client.fetch(settingsQuery, {}).then(settings => settings)

		const navQuery = `*[_id == "navigation"][0].navItems[]{
			title,
			_key,
			_type == 'singleLink' => {
				link-> {
					title,
					"slug": slug.current
				}
			},
			"items": linkList[]->{
				_id,
				title,
				"slug": slug.current,
				"pageIntro": modules[_type == 'pageIntro'][0]{
					"pageThumbnail": media.image,
					theme
				}
			},
			"assetItems": assetList[]{
				"_id": _key,
				title,
				"svgFile": svgFile.asset,
				"assetFile": assetFile.asset
			}
		}`
		const fetchNavigation = client.fetch(navQuery, {}).then(navigation => navigation)

		const allData = Promise.all([fetchPages, fetchSettings, fetchNavigation])
		allData.then(res => {
			setState({ ...state, pages: res[0], settings: res[1], navigation: res[2] })
		})
	}, [])

	return (
		<AppContext.Provider
			value={{
				...state,
				toggleModal: toggleModal,
				setPageTransition: setPageTransition
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export const withAppState = Component => props => (
	<AppContext.Consumer>
		{context => (
			<Component {...props}
				appContext={context}
			/>
		)}
	</AppContext.Consumer>
)

export default AppState
