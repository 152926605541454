import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Grid from 'components/Grid'
import ThemeSelector from 'components/ThemeSelector'
import NoomPath from 'components/NoomPath'
import Image from 'components/Image'
import AtfMedia from './AtfMedia'
import SanityRichText from 'components/SanityRichText'
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right-thick.svg'
import { mq, typography, colors, util } from 'styles'

const Wrapper = styled(Grid)<{ ref?: any }>``

const PathWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	color: ${ colors.sand };
	z-index: 1;
	overflow: hidden;
`

const PositionedPath = styled(NoomPath)<{ positionType?: string }>`
	position: absolute;
	${ ({ positionType }) => `
		${ positionType === 'one' ? `
			left: var(--site-margins);
			top: var(--site-margins);
			right: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			bottom: 50%;
			${ mq.largeAndBelow } {
				left: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			}
			${ mq.mediumAndBelow } {
				right: var(--site-margins);
			}
			${ mq.smallAndBelow } {
				left: var(--site-margins);
				top: 100px;
			}
		` : '' }
		${ positionType === 'two' ? `
			left: var(--site-margins);
			top: var(--site-margins);
			width: 25%;
			top: 50%;
			bottom: calc(20% - var(--path-width) - 2px);
			${ mq.largeAndBelow } {
				left: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			}
			${ mq.smallAndBelow } {
				left: var(--site-margins);
				width: 35%;
			}
		` : '' }
		${ positionType === 'three' ? `
			left: 25%;
			height: 20%;
			right: calc(50% + var(--site-gutters) / 2);
			bottom: 0;
			${ mq.largeAndBelow } {
				width: 40%;
				left: calc(var(--site-margins) + var(--site-gutters) + var(--col-width) + 25%);
			}
			${ mq.mediumAndBelow } {
				width: 30%;
			}
		` : '' }
		${ positionType === 'four' ? `
			height: calc(var(--path-width) * 2);
			top: calc(var(--path-width) * -1 + var(--site-margins) + 2px);
			left: calc(var(--col-width) * 6 + var(--site-gutters) * 6 + var(--site-margins));
			width: calc(var(--path-width) * 2);
			${ mq.smallAndBelow } {
				left: 25%;
				height: calc(var(--path-width) + 100px + 2px);
				top: 0;
			}
		` : '' }
		${ positionType === 'five' ? `
			right: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			bottom: 0;
			height: 50%;
			${ mq.mediumAndBelow } {
				right: var(--site-margins);
			}
		` : '' }
	` }
`

const AtfArrow = styled(ArrowRight)`
	position: absolute;
	top: 0;
	left: 2px;
	width: 30px;
	height: auto;
	transform: rotate(90deg);
	${ mq.largeAndUp } {
		margin-top: -15px;
		top: .5em;
		right: calc(100% + 20px);
		left: unset;
		margin-top: -15px;
		width: 30px;
		height: auto;
		transform: none;
	}
	* {
		fill: var(--main-color);
	}
`

const Atf = styled.div`
	min-height: 100vh;
	padding-top: var(--vertical-spacing);
	padding-bottom: var(--vertical-spacing);
	display: flex;
	position: relative;
	z-index: 2;
	align-items: center;
	justify-content: stretch;
	padding-left: var(--site-margins);
	padding-right: var(--site-margins);
	${ mq.mediumAndUp } {
		padding-left: calc(var(--site-margins) + var(--col-width) + var(--site-gutters));
		padding-right: calc(var(--site-margins) + var(--col-width) + var(--site-gutters));
	}
	.h1, .h2 {
		margin-top: 0;
		margin-bottom: 20px;
		position: relative;
		padding-top: 40px;
		${ mq.largeAndUp } {
			padding-top: 0;
		}
	}
	${ mq.largerAndUp } {
		padding-right: 0;
		margin-right: calc(var(--site-gutters) * -1);
	}
`
const Btf = styled(ThemeSelector)`
	min-height: 100vh;
	padding-top: var(--vertical-spacing);
	padding-bottom: var(--vertical-spacing);
	display: flex;
	align-items: center;
	justify-content: stretch;
	padding-left: var(--site-margins);
	padding-right: var(--site-margins);
	${ mq.mediumAndUp } {
		padding-left: calc(var(--site-margins) + var(--col-width) + var(--site-gutters));
		padding-right: calc(var(--site-margins) + var(--col-width) + var(--site-gutters));
	}
	.h1 {
		margin: 20px 0 1em;
	}
	${ mq.largerAndUp } {
		padding-right: 0;
		margin-right: calc(var(--site-gutters) * -1.05);
	}
`

const MediaCol = styled.div`
	position: relative;
	z-index: -1;
	height: 100vh;
	> div {
		height: 100%;
	}
	${ mq.largerAndUp } {
		height: 100%;
		z-index: 2;
		position: relative;
	}
`

const Eyebrow = styled.h6`
	${ typography.smallCaps }
	margin: 0;
`

const SignatureImage = styled(Image)`
	${ util.responsiveStyles('width', 180, 160, 150, 150) }
	margin-top: 30px;
`

interface sectionData {
  [x: string]: any
}

type HomeAtfProps = {
  className?: string,
  verticalMedia: object,
  atf: sectionData,
  btf: sectionData
}

const HomeAtf = ({
	className = '',
	verticalMedia,
	atf,
	btf
}: HomeAtfProps) => {
	return (
		<Wrapper
			className={className}
			small='[1]'
			large='[1] [1]'
			colGap='var(--site-gutters)'
			vAlign='top'
		>
			<div>
				<Atf>
					<Grid
						small='[1]'
						medium='[4] 1'
						large='1 [4] 1'
						colGap='var(--site-gutters)'
					>
						<h1 className='h2'><AtfArrow/>{atf.atfHeadline}</h1>
						<div><SanityRichText text={atf.atfText} /></div>
					</Grid>
				</Atf>
				<Btf setTheme={btf.btfTheme || 'blueberry'}>
					<Grid
						small='[1]'
						medium='[4] 1'
						large='[5] 1'
						colGap='var(--site-gutters)'
					>
						{btf.btfEyebrow && (<Eyebrow>{btf.btfEyebrow}</Eyebrow>)}
						<h3 className='h1'>{btf.btfHeadline}</h3>
						{btf.btfText && (<div><SanityRichText text={btf.btfText} /></div>)}
						{btf.btfSignature && (<SignatureImage quality={100} sizes='360px' image={btf.btfSignature}/>)}
					</Grid>
				</Btf>
			</div>
			<MediaCol>
				<AtfMedia
					setTheme={btf.btfTheme || 'blueberry'}
					media={verticalMedia}
					image={{
						sizes: '(min-width: ' + mq.largeBreakpoint + 'px) 50vw, 100vw'
					}}
				/>
			</MediaCol>
			<PathWrapper>
				<PositionedPath
					positionType='one'
					bottom={false}
				/>
				<PositionedPath
					positionType='two'
					right={false}
					top={false}
				/>
				<PositionedPath
					positionType='three'
					left={false}
					bottom={false}
				/>
				<PositionedPath
					positionType='four'
					top={false}
					right={false}
				/>
				<PositionedPath
					positionType='five'
					top={false}
					bottom={false}
					right={false}
				/>
			</PathWrapper>
		</Wrapper>
	)
}

export default HomeAtf
