import React from 'react'
import styled from '@emotion/styled'
import Section from 'components/Section'
import Grid from 'components/Grid'
import AssetCard from 'components/AssetCard'
import AssetCardSmall from 'components/AssetCardSmall'
import { typography } from 'styles'

const Wrapper = styled(Grid)``

const SectionTitle = styled.h4`
	margin-top: 0;
	margin-bottom: calc(var(--vertical-spacing) / 2);
	${ typography.h6 }
`

type AssetGridProps = {
  className?: string,
  title?: string,
  assets: Array<object>,
  cardSize?: string,
  previewColor?: string,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const AssetGrid = ({
	className,
	title,
	assets,
	cardSize,
	previewColor,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: AssetGridProps) => {
	if (assets?.length < 1) {
		return null
	}

	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				margins
			>
				{title && (<div><SectionTitle>{title}</SectionTitle></div>)}
				<div>
					<Grid
						small={cardSize === 'small' ? '[1] [1]' : '[1]'}
						medium={cardSize === 'small' ? '[1] [1] [1]' : '[1] [1] [1]'}
						large={cardSize === 'small' ? '[1] [1] [1] [1]' : '[1] [1] [1]'}
						larger={cardSize === 'small' ? '[1] [1] [1] [1] [1] [1]' : '[1] [1] [1]'}
						colGap='var(--site-gutters)'
						rowGap='var(--site-gutters)'
					>
						{assets.map((asset: any) => {
							return (
								<div key={asset.svgFile.asset._ref} style={{ height: '100%' }}>
									{cardSize === 'small' ? (
										<AssetCardSmall
											title={asset.title}
											svgAsset={asset.svgFile}
											asset={asset?.assetFile?.asset}
											color={previewColor}
										/>
									) : (
										<AssetCard
											title={asset.title}
											svgAsset={asset.svgFile}
											asset={asset?.assetFile?.asset}
											color={previewColor}
										/>
									)}
								</div>
							)
						})}
					</Grid>
				</div>
			</Wrapper>
		</Section>
	)
}

export default AssetGrid
