import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import { Global, css } from '@emotion/react'
import globalStyles from 'styles/globalStyles'
import Page from 'containers/Page'
import MainNav from 'components/MainNav'
import Footer from 'components/Footer'
import AppProvider from 'state/AppState'
import PageTransition from 'components/PageTransition'

const getWindowDimensions = () => {
  const winWidth = document?.body?.clientWidth
  const { innerWidth: width, innerHeight: height } = window;
  // console.log(width, winWidth)
  return {
    width,
    height,
    winWidth
  }
}

function App() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <Global styles={css`
        ${ globalStyles(windowDimensions.winWidth) }
      `} />
      <AppProvider>
        <div className="App">
          <Router>
            <MainNav />
            <PageTransition location={window.location.pathname}>
              <Routes>
                <Route element={<Page/>} path="/"/>
                <Route element={<Page/>} path="/:slug"/>
                <Route element={<Page/>} path="/:section/:slug"/>
              </Routes>
            </PageTransition>
            <Footer />
          </Router>
        </div>
      </AppProvider>
    </>
  )
}

export default App
