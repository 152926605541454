import React from 'react'

const getClassName = (className, first, last) => {
  let classText = className
  if (first) {
    classText = classText + ' first-item'
  }
  if (last) {
    classText = classText + ' last-item'
  }
  return classText
}

export const Serializer = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className={getClassName('h1', props.node.firstItem, props.node.lastItem)}>{props.children}</h1>

        case 'h2':
          return <h2 className={getClassName('h2', props.node.firstItem, props.node.lastItem)}>{props.children}</h2>

        case 'h3':
          return <h3 className={getClassName('h3', props.node.firstItem, props.node.lastItem)}>{props.children}</h3>

        case 'h4':
          return <h4 className={getClassName('h4', props.node.firstItem, props.node.lastItem)}>{props.children}</h4>

        case 'h5':
          return <h5 className={getClassName('h5', props.node.firstItem, props.node.lastItem)}>{props.children}</h5>

        case 'li':
          return <li>{props.children}</li>

        case 'blockquote':
          return <blockquote className={getClassName('', props.node.firstItem, props.node.lastItem)}>{props.children}</blockquote>

        case 'bodyLarge':
          if (props.listItem) return <strong>{props.children}</strong>
          else return <p className={getClassName('large', props.node.firstItem, props.node.lastItem)}>{props.children}</p>

        case 'bodyMedium':
          if (props.listItem) return <strong>{props.children}</strong>
          else return <p className={getClassName('medium', props.node.firstItem, props.node.lastItem)}>{props.children}</p>

        case 'bodySmall':
          if (props.listItem) return <strong>{props.children}</strong>
          else return <p className={getClassName('small', props.node.firstItem, props.node.lastItem)}>{props.children}</p>

        case 'normal':
          if (props.listItem) return <strong>{props.children}</strong>
          else return <p className={getClassName('', props.node.firstItem, props.node.lastItem)}>{props.children}</p>
        default:
          return <p className={getClassName('', props.node.firstItem, props.node.lastItem)}>{props.children}</p>
      }
    }
  },
  marks: {
    tick: props => (
      <span className='tick'>{props.children}</span>
    ),
    italic: props => (
      <em>{props.children}</em>
    ),
    strong: props => (
      <strong>{props.children}</strong>
    ),
    code: props => (
      <code>{props.children}</code>
    ),
    gold: props => (
      <span className='gold'>{props.children}</span>
    ),
    link: props => {
      const action = props.mark.url
      console.log(props)
      return (
        <a
          href={action}
          title={props.children}
          name={props.children}
        >{props.children}</a>
      )
    },
  }
}
