import React, { useContext } from 'react'
import imageUrlBuilder from '@sanity/image-url'
import { getFileAsset, getImageDimensions } from '@sanity/asset-utils'
import { AppContext } from 'state/AppState'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ReactComponent as ArrowDown } from 'assets/images/arrow-stem-down.svg'
import { animations, typography, colors } from 'styles'
import SvgAsset from 'components/SvgAsset'

const sanityOptions = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
}

const DownloadIconWrapper = styled.div`
	position: relative;
	overflow: hidden;
	transform-style: preserve3d;
	flex-grow: 0;
	flex-shrink: 0;
	transition: color ${ animations.mediumSpeed } ease-in-out;
	&:after {
		content: '';
		display: block;
		width: 11px;
		height: 2px;
		background: currentcolor;
		margin: 3px auto 0;
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	svg {
		&:first-of-type {
			opacity: 0;
		}
		display: block;
		overflow: hidden;
		height: 15px;
		width: 15px;
		* {
			fill: currentcolor;
		}
	}
`

const AssetPreview = styled.div<{ color?: string, wideIcon?: boolean }>`
	padding-right: 30px;
	flex-grow: 1;
	height: 40px;
	width: 160px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	flex-grow: 0;
	svg {
		display: block;
		max-height: 100%;
		max-width: 100%;
		${ ({ wideIcon }) => wideIcon ? `
			height: auto;
			width: 100%;
		` : `
			height: 100%;
			width: auto;
		` }
		rect, path, circle, ellipse, polygon {
			stroke: transparent;
			&:not([fill="none"]) {
				stroke: transparent;
				fill: ${ ({ color }) => color ? colors[color] : colors.sand };
			}
		}
	}
`

const Wrapper = styled.a<{ className?: string, title?: string, href: string }>`
	background: var(--card-bg);
	border-radius: 9px;
	padding: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	cursor: pointer;
	height: 100%;
	${ typography.body }
	&:hover {
		.download-icon {
			color: var(--main-color);
			svg {
				transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
				transform: translate3d(0, 100%, 0);
				&:first-of-type {
					opacity: 1;
				}
				&:last-of-type {
					opacity: 1;
					transform: translate3d(0, 120%, 0);
				}
			}
			&:after {
				transform: scaleX(1.5);
			}
		}
	}
`

const CardRow = styled.div<{ position?: string, cardHeight?: string }>`
	display: flex;
	width: 100%;
	align-items: baseline;
	justify-content: space-between;
	${ ({ position, cardHeight }) => position === 'top' && `
		${ cardHeight !== 'short' ? `
			margin-bottom: 30%;
		` : `
			margin-bottom: 20%;
		` }
	` }
`

const CardTitle = styled.div`
	${ typography.bodyMedium }
	margin-top: -.2em;
`

type AssetCardProps = {
	className?: string,
	title?: any,
	svgAsset?: any,
	asset?: any,
	color?: string,
	cardHeight?: string
}

const AssetCard = ({ className = '', asset, svgAsset, title, cardHeight, color = 'sand' }: AssetCardProps) => {
	const { sanity } = useContext(AppContext)
	const urlFor = (source: any) => {
		return imageUrlBuilder(sanity).image(source)
	}
	
	if (!svgAsset || !title) {
		return null
	}

	const fileUrl = asset ? getFileAsset(asset?._ref, sanityOptions)?.url : ''
	const svgUrl = urlFor(svgAsset).url() || ''
	const downloadUrl = fileUrl || svgUrl

	const wideIcon = getImageDimensions(svgAsset).aspectRatio >= 4.333

	return (
		<Wrapper
			className={className}
			href={downloadUrl}
			title={'Download ' + title}
			target='_blank'
			rel='noopener noreferrer'
		>
			<CardRow position='top' cardHeight={cardHeight}>
				<CardTitle className='card-title'>{title}</CardTitle>
			</CardRow>
			<CardRow>
				<AssetPreview color={color} wideIcon={wideIcon}>
					<SvgAsset asset={svgAsset} />
				</AssetPreview>
				<DownloadIconWrapper className='download-icon'>
					<ArrowDown/>
					<ArrowDown/>
				</DownloadIconWrapper>
			</CardRow>
		</Wrapper>
	)
}

export default AssetCard