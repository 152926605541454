import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Grid from 'components/Grid'
import Media from 'components/Media'
import Section from 'components/Section'
import SanityRichText from 'components/SanityRichText'
import StarHeadline from 'components/StarHeadline'
import { typography, colors, mq, util } from 'styles'
import { slugify } from 'utils/format'
import { MdCheck, MdClose } from 'react-icons/md'

const StyledSection = styled(Section)`
	padding-top: 100px;
	margin-top: -100px;
`

const Wrapper = styled(Grid)`
	> div {
		height: 100%;
	}
`

const ItemIcon = styled.div<{ color?: any }>`
	top: 0;
	left: 0;
	display: inline-block;
	font-size: 18px;
	vertical-align: top;
	border-radius: 50%;
	padding: .2em;
	background: ${ ({ color }) => color || 'var(--text-color)' };
	color: ${ colors.white };
	margin-bottom: var(--site-gutters);
	svg {
		display: block;
	}
`

const IntroText = styled.div`
	p {
		${ typography.bodyMedium }
	}
`

const StickyContent = styled.div`
	${ mq.largerAndUp } {
		position: sticky;
		top: 100px;
	}
`

const SectionTitle = styled.h3<{ hasMedia?: boolean }>`
	${ typography.h6 }
	margin-bottom: 30px;
	${ ({ hasMedia }) => hasMedia === false ? `
		${ util.responsiveStyles('margin-top', 26, 18, 18, 16) }
		${ mq.largeAndBelow } {
			${ util.responsiveStyles('margin-top', 120, 100, 80, 60) }
		}
	` : `
		${ util.responsiveStyles('margin-top', 120, 100, 80, 60) }
	` }
`

const Card = styled.div`
	background: ${ colors.white };
	margin-top: 40px;
	${ util.responsiveStyles('padding', 50, 40, 40, 30) }
	p {
		margin-bottom: 0;
		max-width: 42em;
	}
	${ mq.mediumAndBelow } {
		margin-top: var(--site-margins);
	}
`

const Eyebrow = styled.p`
	${ typography.smallCaps }
	margin-top: 0;
`

const ToneList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 14px 0 0;
	${ util.responsiveStyles('margin-bottom', 120, 100, 80, 60) }
	li {
		${ typography.h4 }
		padding-left: 24px;
		position: relative;
		&:before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background: ${ colors.red };
			position: absolute;
			top: .45em;
			left: 0;
			transform: rotate(45deg);
		}
	}
`

const DirectiveHeadline = styled.h4`
	margin-top: -.15em;
	${ mq.mediumAndUp } {
		margin-bottom: 5%;
	}
`

const SectionMedia = styled(Media)`
	margin-bottom: 20px;
`

const MediaWrapper = styled.div`
	${ util.responsiveStyles('margin-bottom', 120, 100, 80, 60) }
`

const TraitsSecondary = styled.div`
	${ util.responsiveStyles('margin-top', 60, 50, 40, 40) }
`

const MediaHeadline = styled.p`
	${ typography.h6 }
	max-width: 26em;
`
const MediaText = styled.p`
	${ typography.body }
	max-width: 26em;
`

type StickyCardsProps = {
  className?: string,
  title: string,
  text?: string,
  media?: any,
  traitsHeadline?: string,
  traitsSection: any,
  voiceHeadline?: string,
  voiceDirectives: Array<object>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean,
	_key?: string,
	navTitle?: string,
	sectionDivider?: boolean,
	mediaCaptionHeadline?: string,
	mediaCaptionText?: string
}

const StickyCards = ({
	className,
	title,
	text,
	media,
	mediaCaptionHeadline,
	mediaCaptionText,
	traitsHeadline,
	traitsSection,
	voiceHeadline,
	voiceDirectives,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection,
	_key,
	navTitle,
	sectionDivider
}: StickyCardsProps) => {
	const hasMedia = media?.image?.asset || media?.video?.asset || false
	
	const getIcon = (iconType?: string) => {
		let icon = null
		if (iconType === 'do') {
			icon = <ItemIcon color={colors.kale}><MdCheck /></ItemIcon>
		} else if (iconType === 'dont') {
			icon = <ItemIcon color={colors.red}><MdClose /></ItemIcon>
		}
		return icon
	}
	return (
		<StyledSection
			id={_key}
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
			navTitle={navTitle}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				large='1 [5] 1 [6] 1'
				colGap='var(--site-gutters)'
				margins
				vAlign='top'
			>
				<div>
					<StickyContent>
						{navTitle && (
							<div>
								<StarHeadline border={sectionDivider} style={{ marginBottom: '30px' }}>{navTitle}</StarHeadline>
							</div>
						)}
						<h3 style={{ marginTop: 0 }}>{title}</h3>
						{text && Array.isArray(text) && (
							<IntroText><SanityRichText text={text} /></IntroText>
						)}
					</StickyContent>
				</div>
				<div>
					{hasMedia && (
						<MediaWrapper>
							<SectionMedia media={media} />
							{mediaCaptionHeadline && <MediaHeadline>{mediaCaptionHeadline}</MediaHeadline>}
							{mediaCaptionText && <MediaText>{mediaCaptionText}</MediaText>}
						</MediaWrapper>
					)}
					{(traitsSection?.traits?.length > 0 || traitsSection?.eyebrowText || traitsSection?.headline || traitsSection?.text) && (
						<>
							<SectionTitle hasMedia={hasMedia}>{traitsHeadline || 'Traits'}</SectionTitle>
							<Card>
								{traitsSection?.listHeader && (<Eyebrow>{traitsSection.listHeader}</Eyebrow>)}
								{traitsSection?.traits?.length > 0 && (
									<ToneList>
										{traitsSection.traits.map((trait: string, index: number) => (
											<li key={slugify(trait) + index}>{trait}</li>
										))}
									</ToneList>
								)}

								{console.log(title, traitsSection)}

								{getIcon(traitsSection?.iconType1)}
								{traitsSection?.eyebrowText && (<Eyebrow>{traitsSection.eyebrowText}</Eyebrow>)}
								{traitsSection?.headline && (<h4>{traitsSection.headline}</h4>)}
								{traitsSection?.text && Array.isArray(traitsSection.text) && (<SanityRichText text={traitsSection.text} />)}

								{(traitsSection?.headline2 || traitsSection?.text2) && (
									<TraitsSecondary>
										{getIcon(traitsSection?.iconType2)}
										{traitsSection?.eyebrowText2 && (<Eyebrow>{traitsSection.eyebrowText2}</Eyebrow>)}
										{traitsSection?.headline2 && (<h4>{traitsSection.headline2}</h4>)}
										{traitsSection?.text2 && Array.isArray(traitsSection.text2) && (<SanityRichText text={traitsSection.text2} />)}
									</TraitsSecondary>
								)}

							</Card>
						</>
					)}
					{voiceDirectives?.length > 0 && (
						<>
							<SectionTitle>{voiceHeadline || 'Voice Directives'}</SectionTitle>
							{voiceDirectives.map((directive: any) => {
								return (
									<Card key={directive._key}>
										<DirectiveHeadline>{directive.headline}</DirectiveHeadline>
										{directive?.text && Array.isArray(directive.text) && (<SanityRichText text={directive.text} />)}
									</Card>
								)
							})}
						</>
					)}
				</div>
			</Wrapper>
		</StyledSection>
	)
}

export default StickyCards
