import React, { useEffect } from 'react'
import { Stickyroll } from '@stickyroll/stickyroll'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Grid from 'components/Grid'
import NoomPath from 'components/NoomPath'
import Section from 'components/Section'
import ThemeSelector from 'components/ThemeSelector'
import Image from 'components/Image'
import Video from 'components/Video'
import VerticalVideo from './VerticalVideo'
import ResponsiveComponent from 'components/ResponsiveComponent'
import { ReactComponent as ArrowDown } from 'assets/images/arrow-right-thick.svg'
import { getImageDimensions } from '@sanity/asset-utils'
import { mq, typography, util } from 'styles'

const Wrapper = styled(ThemeSelector)<{ setTheme: string }>`
	padding-top: var(--vertical-spacing);
	padding-bottom: var(--vertical-spacing);
	${ mq.largerAndUp } {
		padding-top: 0;
		padding-bottom: 0;
		overflow: hidden;
		display: flex;
	}
`

const SectionContent = styled.div<{ progress: number }>`
	--progress: ${ ({ progress }) => progress };
	${ mq.largeAndUp } {
		margin-left: calc(var(--progress) * 100vw);
		transform: translate3d(calc(var(--progress) * -100%), 0, 0);
		min-width: 100%;
		width: auto;
		flex-shrink: 0;
		flex-wrap: nowrap;
		position: relative;
		height: 100vh;
		display: flex;
		padding-left: calc(var(--col-width) + var(--site-margins) + var(--site-gutters));
		padding-right: calc(var(--col-width) + var(--site-margins) + var(--site-gutters));
	}
`

const SectionPaths = styled.div`
	${ mq.largeAndUp } {
		position: absolute;
		z-index: 1;
		top: calc(var(--vertical-spacing) * -1);
		bottom: 0;
		right: 0;
		left: calc(var(--col-width) * 7 + var(--site-gutters) * 7 + var(--site-margins));
	}
`

const SectionPath = styled(NoomPath)`
	${ mq.largeAndUp } {
		position: absolute;
		top: 0;
		bottom: var(--vertical-spacing);
		right: 0;
		left: 0;
	}
	.path-label {
		top: 0;
		left: 0;
		right: unset;
		display: flex;
		white-space: nowrap;
		justify-content: center;
		width: var(--path-width);
		transform: rotate(90deg);
		> div {
			display: flex;
			padding: 0 3em;
		}
		svg {
			flex-shrink: 0;
			flex-grow: 0;
			display: block;
			margin-top: 1px;
			margin-left: 3em;
		}
		${ typography.smallCaps }
		${ mq.largerAndUp } {
			justify-content: flex-start;
			top: var(--vertical-spacing);
		}
	}
`

const PathArrow = styled(ArrowDown)`
	position: absolute;
	bottom: calc(var(--path-width) / 2 + var(--vertical-spacing));
	left: calc(var(--path-width) * 2);
	margin: -8px;
`

const EndPath = styled(NoomPath)`
	${ mq.largeAndUp } {
		position: absolute;
		top: -1px;
		width: calc(var(--col-width) * 2);
		bottom: calc(var(--vertical-spacing) * -1 - 1px);
		right: calc(var(--col-width) + var(--site-margins) + var(--site-gutters));
	}
`

const IntroText = styled.div`
	${ mq.largerAndUp } {
		flex-shrink: 0;
		width: calc(var(--col-width) * 6 + var(--site-gutters) * 6);
		margin-right: calc(var(--path-width) + var(--col-width) + var(--site-gutters) * 2);
		padding-right: calc(var(--col-width) * 1 + var(--site-gutters) * 1);
		margin-bottom: var(--col-width);
		align-items: center;
		justify-content: flex-start;
		display: flex;
		> div {
			width: 100%;
		}
	}
	p {
		${ typography.h4 }
		max-width: 20em;
	}
`

const HSection = styled.div<{sectionType: string, firstSection: boolean}>`
	position: relative;
	z-index: 2;
	margin-top: var(--vertical-spacing);
	${ ({ sectionType, firstSection }) => sectionType === 'image' || sectionType === 'video' ? `
		margin-bottom: calc(var(--col-width) * .5 + var(--vertical-spacing));
		${ !firstSection ? `
			margin-left: var(--col-width);
		` : `` }
	` : `
		margin-bottom: calc(var(--col-width) + var(--vertical-spacing));
		${ !firstSection ? `
			margin-left: var(--col-width);
		` : `` }
	` }
	flex-shrink: 0;
	p {
		max-width: 520px;
	}
	--media-height: calc(100vh - var(--vertical-spacing) - (var(--col-width) * .5 + var(--vertical-spacing)));
`

const VerticalImage = styled(Image)<{ ratio?: number }>`
	${ mq.largerAndUp } {
		height: 100%;
		width: ${ ({ ratio }) => `calc(var(--media-height) * ${ ratio })`};
	}
`

const LargeTextSection = styled(HSection)<{hasBodyCopy: boolean}>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	${ ({ hasBodyCopy }) => hasBodyCopy ? `
		justify-content: flex-start;
	` : `
		justify-content: flex-end;
	` }
`

const LargeTextHeadline = styled.h3`
	${ util.responsiveStyles('font-size', 200, 144, 100, 80) }
`

const LargeTextColumns = styled.div`
	${ mq.largeAndUp } {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-top: 30px;
		width: 100%;
		> p {
			margin-top: 0;
			width: calc(50% - 40px);
			flex-grow: 1;
			max-width: 650px;
			&:first-of-type {
				margin-right: 80px;
			}
		}
	}
`

const LargeParagraph = styled.p`
	${ typography.h4 }
`

const MobileHeadline = styled.h3`
	${ typography.h1 }
	max-width: 10em;
`

interface sectionData {
  [x: string]: any
}

type HorizontalContentProps = {
  className?: string,
  theme: string,
  introHeadline: string,
  introText: string,
  leadDownText?: string,
  sections: Array<sectionData>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

/*
CONTENT TYPES
- Large text with paragraph text
	Large text on bottom
	Large text with paragraph and large paragraph
- Eyebrow, Large Paragraph and paragraph
- Media
*/

const HorizontalContent = ({
	className,
	theme,
	introHeadline,
	introText,
	leadDownText,
	sections,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: HorizontalContentProps) => {
	const largeTextSections = sections.filter(section => section?._type === 'largeText')
	
	const getVideoRatio = (key: any) => {
		let ratio = 9 / 16
		const videoItem = document.querySelector('#h-video-' + key)
		if (videoItem !== null && videoItem instanceof HTMLElement) {
			console.log(videoItem.offsetHeight / videoItem.offsetWidth)
			ratio = videoItem.offsetHeight / videoItem.offsetWidth
		}
		return ratio
	}

	var sectionRatios: String[] = [];

	useEffect(() => {
		sections.forEach(() => {
			console.log('sectin')
			sectionRatios.push('item')
		})
	}, [])


	console.log(sectionRatios)

	return (
		<Section
			theme={theme || 'gold'}
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<ResponsiveComponent
				small={
					<Wrapper setTheme={theme || 'gold'}>
						<Grid
							small='[1]'
							medium='1 [12] 1'
							colGap='var(--site-gutters)'
							rowGap='var(--site-gutters)'
							margins
						>
							<div>
								<IntroText>
									<div>
										<h6>{introHeadline}</h6>
										<p>{introText}</p>
									</div>
								</IntroText>
							</div>
							<MobileHeadline>
								{largeTextSections && largeTextSections.length > 0 && largeTextSections.map((section: any, index: number) => (
									<>{section.headline}&nbsp;</>
								))}
							</MobileHeadline>
							{sections.map((section: any, index: number) => {
								switch (section._type) {
									case ('image'):
										return (
											<div key={section._key}>
												<Image image={section.asset}/>
											</div>
										)

									case ('video'):
										return (
											<div key={section._key}>
												<Video video={section} />
											</div>
										)

									case ('largeText'):
										const hasBodyCopy = section?.largeParagraph || section?.smallParagraph
										if (!hasBodyCopy) {
											return null
										}
										return (
											<div key={section._key}>
												<LargeTextColumns>
													{section?.eyebrow && (<h6 style={{ marginTop: 0 }}>{section.eyebrow}</h6>)}
													{section?.largeParagraph && (<LargeParagraph>{section.largeParagraph}</LargeParagraph>)}
													{section?.smallParagraph && (<p>{section.smallParagraph}</p>)}
												</LargeTextColumns>
											</div>
										)

									case ('textSection'):
										return (
											<div key={section._key}>
												{section?.eyebrow && (<h6 style={{ marginTop: 0 }}>{section.eyebrow}</h6>)}
												{section?.largeText && (<LargeParagraph style={{ marginTop: 0 }}>{section.largeText}</LargeParagraph>)}
												{section?.smallText && (<p style={{ marginTop: 0 }}>{section.smallText}</p>)}
											</div>
										)

									default:
										return null
								}
							})}
						</Grid>
					</Wrapper>
				}
				large={
					<Stickyroll pages={['one']} factor={sections.length * .75}>
						{({ progress }) => {
							return (
								<Wrapper setTheme={theme || 'gold'}>
									<SectionContent progress={progress}>
										<SectionPaths>
											<SectionPath
												top={false}
												right={false}
												label={<div>{leadDownText || 'Right this way'}</div>}
											>
												<EndPath
													left={false}
													bottom={false}
												/>
											</SectionPath>
											<PathArrow/>
										</SectionPaths>
										<IntroText>
											<div>
												<h6>{introHeadline}</h6>
												<p>{introText}</p>
											</div>
										</IntroText>
										{sections.map((section: any, index: number) => {
											switch (section._type) {
												case ('image'):
													return (
														<HSection firstSection={index === 0} key={section._key} sectionType={section._type}>
															<VerticalImage image={section.asset} ratio={getImageDimensions(section.asset).aspectRatio} />
														</HSection>
													)

												case ('video'):
													return (
														<HSection firstSection={index === 0} key={section._key} sectionType={section._type}>
															<VerticalVideo video={section} sectionKey={section._key} />
														</HSection>
													)

												case ('largeText'):
													return (
														<LargeTextSection
															firstSection={index === 0}
															key={section._key}
															sectionType={section._type}
															hasBodyCopy={section?.largeParagraph || section?.smallParagraph}
														>
															{section?.eyebrow && (<h6 style={{ marginTop: 0 }}>{section.eyebrow}</h6>)}
															<LargeTextHeadline>{section.headline}</LargeTextHeadline>
															<LargeTextColumns>
																{section?.largeParagraph && (<LargeParagraph>{section.largeParagraph}</LargeParagraph>)}
																{section?.smallParagraph && (<p>{section.smallParagraph}</p>)}
															</LargeTextColumns>
														</LargeTextSection>
													)

												case ('textSection'):
													return (
														<HSection firstSection={index === 0} key={section._key} sectionType={section._type}>
															{section?.eyebrow && (<h6 style={{ marginTop: 0 }}>{section.eyebrow}</h6>)}
															{section?.largeText && (<LargeParagraph style={{ marginTop: 0 }}>{section.largeText}</LargeParagraph>)}
															{section?.smallText && (<p style={{ marginTop: 0 }}>{section.smallText}</p>)}
														</HSection>
													)

												default:
													return null
											}
										})}
									</SectionContent>
								</Wrapper>
							)
						}}
					</Stickyroll>
				}
			/>
		</Section>
	)
}

export default HorizontalContent
