import React, { useState } from 'react'
import styled from '@emotion/styled'
import Section from 'components/Section'
import Grid from 'components/Grid'
import Image from 'components/Image'
import LevelsChart from 'components/LevelsChart'
import ResponsiveComponent from 'components/ResponsiveComponent'
import { colors, util, mq, typography, animations } from 'styles'
import { InView } from 'react-intersection-observer'

const Wrapper = styled(Grid)``

const StickyContent = styled.div`
	${ mq.largerAndUp } {
		position: sticky;
		top: 60px;
	}
`

const CardImage = styled(Image)`
	margin-bottom: var(--site-gutters);
`

const SectionCard = styled.div`
	background: ${ colors.white };
	margin-top: var(--site-margins);
	${ util.responsiveStyles('padding', 50, 40, 40, 30) }
	h5 {
		margin: -.4em 0 0;
		+ p {
			margin-top: .5em;
		}
	}
	p {
		margin: 0;
		&.no-title {
			${ typography.bodyMedium }
			font-family: ${ typography.secondaryFont };
		}
	}
`

const ChartTitle = styled.div<{ currentSection: number }>`
	${ typography.smallCaps }
	line-height: 1em;
	text-align: center;
	margin-bottom: calc(var(--site-margins) * 1.5);
	height: 1em;
	overflow: hidden;
	width: 100%;
	> div {
		width: 100%;
		${ mq.largerAndUp } {
			transition: transform ${ animations.mediumSpeed } ease-in-out;
			transform: translate3d(0, ${ ({ currentSection }) => -1 * currentSection }em, 0);
		}
	}
	span {
		display: block;
	}
`

const ChartWrapper = styled.div`
	border: 1px solid ${ colors.gold };
	padding: calc(var(--site-margins) * 1.5) var(--site-margins);
`

interface sectionItem {
	title?: string,
	text: string,
	image?: any
}

interface sectionData {
	_key: string,
	title: string,
	text: string,
	chartValues: {
		knowing: string,
		magnetic: string,
		straightUp: string,
		supportive: string
	},
	items: Array<sectionItem>
}

type StickyNumberChartProps = {
  className?: string,
  title?: string,
  text?: string,
  sections: Array<sectionData>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const StickyNumberChart = ({
	className,
	sections,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: StickyNumberChartProps) => {
	const [currentSection, setCurrentSection] = useState(0)
	if (!sections || sections.length < 1) {
		return null
	}

	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				margins
			>
				<div>
					<Grid
						small='[1]'
						large='[11] 1 [12]'
						larger='[4] 2 [6]'
						colGap='var(--site-gutters)'
						rowGap='var(--site-gutters)'
						vAlign='top'
					>
						<ResponsiveComponent
							small={<></>}
							large={
								<StickyContent>
									<ChartWrapper>
										<ChartTitle currentSection={currentSection}>
											<div>
												{sections.map((section: sectionData) => (
													<span>{section.title}</span>
												))}
											</div>
										</ChartTitle>
										<LevelsChart
											chartSize='small'
											valueOne={parseInt(sections[currentSection].chartValues.knowing)}
											valueTwo={parseInt(sections[currentSection].chartValues.supportive)}
											valueThree={parseInt(sections[currentSection].chartValues.straightUp)}
											valueFour={parseInt(sections[currentSection].chartValues.magnetic)}
										/>
									</ChartWrapper>
								</StickyContent>
							}
						/>
						<div>
							{sections.map((section: sectionData, index) => (
								<InView
									style={{ marginTop: index !== 0 ? 'var(--vertical-spacing)' : 0 }}
									onChange={(inView, entry) => { if (inView) { setCurrentSection(index) } else { return false } }}
									key={section._key}
									threshold={.5}
								>
									<ResponsiveComponent
										large={<></>}
										small={
											<div style={{ marginBottom: 'calc(var(--vertical-spacing) * .5)' }}>
												<ChartWrapper>
													<ChartTitle currentSection={currentSection}>
														<div>
															<span>{section.title}</span>
														</div>
													</ChartTitle>
													<LevelsChart
														chartSize='small'
														valueOne={parseInt(sections[index].chartValues.knowing)}
														valueTwo={parseInt(sections[index].chartValues.supportive)}
														valueThree={parseInt(sections[index].chartValues.straightUp)}
														valueFour={parseInt(sections[index].chartValues.magnetic)}
													/>
												</ChartWrapper>
											</div>
										}
									/>
									<h6>{section.title}</h6>
									<p style={{ marginBottom: '2.5em' }}>{section.text}</p>
									{section.items && section.items.length > 0 && section.items.map((item: sectionItem) => (
										<SectionCard>
											{item?.image && (<CardImage image={item.image} />)}
											{item?.title && (<h5>{item.title}</h5>)}
											{item?.text && (<p className={!item.title ? 'no-title' : ''}>{item.text}</p>)}
										</SectionCard>
									))}
								</InView>
							))}
						</div>
					</Grid>
				</div>
			</Wrapper>
		</Section>
	)
}

export default StickyNumberChart
