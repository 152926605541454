import React from 'react'
import styled from '@emotion/styled'
import * as Scroll from 'react-scroll'

const Wrapper = styled.section<{
  className?: string,
  as?: any,
  name?: string,
  setTheme?: string,
  prevTheme?: string,
  nextTheme?: string,
  isFirstSection?: boolean,
  isLastSection?: boolean
}>`
  margin-bottom: var(--vertical-spacing);
  ${ ({ prevTheme, nextTheme, setTheme }) => `
    ${ setTheme && prevTheme !== 'default' ? `
    ` : `
      ${ prevTheme && prevTheme !== 'default' ? `
        margin-top: var(--vertical-spacing);
      ` : '' }
      ${ nextTheme && prevTheme !== 'default' ? `
        margin-top: var(--vertical-spacing);
      ` : '' }
    ` }
  ` }
  ${ ({ isLastSection, setTheme }) => isLastSection && setTheme ? `
    margin-bottom: 0;
  ` : `` }
`

const ScrollElement = Scroll.Element

type SectionProps = {
  className?: string,
  children?: any,
  as?: any,
  id?: string,
  theme?: string,
  nextTheme?: string,
  prevTheme?: string,
  isFirstSection?: boolean,
  isLastSection?: boolean,
  navTitle?: string
}

const Section = ({
  className = '',
  children,
  as,
  id,
  theme,
  nextTheme,
  prevTheme,
  isFirstSection,
  isLastSection,
  navTitle
}: SectionProps) => {
  if (id) {
    as = ScrollElement
  }
  return (
    <Wrapper
      className={className}
      as={as}
      id={id}
      name={id}
      setTheme={theme}
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      isFirstSection={isFirstSection}
      isLastSection={isLastSection}
    >
      {/*
      prevTheme: {prevTheme}<br/>
      theme: {theme}<br/>
      nextTheme: {nextTheme}<br/>
      */}
      {children}
    </Wrapper>
  )
}

export default Section