import React, { useRef, useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import lottie from 'lottie-web'
import styled from '@emotion/styled'
import { tint } from 'polished'
import { css } from '@emotion/react'
import Logo from 'components/Logo'
import AssetCard from 'components/AssetCard'
import { AppContext } from 'state/AppState'
import { useWindowWidth } from '@react-hook/window-size'
import { colors, typography, mq, animations } from 'styles'
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg'
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right.svg'
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg'
import { ReactComponent as IconDownload } from 'assets/images/icon-download.svg'
import animatedLogoJson from 'assets/logo-animation.json'

const Wrapper = styled.div<{ navCollapsed?: boolean }>`
	--row-height: 52px;
	--h-padding: 20px;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	// width: 100%;
	position: fixed;
	z-index: 6;
	top: 0;
	left: 0;
	right: 0;
	padding-top: var(--site-margins);
	padding-bottom: var(--site-margins);
	padding-left: var(--site-margins);
	padding-right: var(--site-margins);
	transition: max-width ${ animations.mediumSpeed } ease-in-out;
	${ ({ navCollapsed }) => navCollapsed ? `
		max-width: calc(121px + var(--site-margins) * 2);
		transition-delay: .2s;
	` : `
		max-width: calc(354px + var(--site-margins) * 2);
	` }
	${ mq.smallAndBelow } {
		max-width: 100%;
	}
`

const NavBar = styled.div<{ navCollapsed?: boolean }>`
	--row-height: 52px;
	--h-padding: 20px;
	overflow: hidden;
	pointer-events: all;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	border-radius: 10px;
	min-height: var(--row-height);
	max-height: calc(100vh - var(--site-margins) * 2);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	// width: 100%;
	position: relative;
	z-index: 4;
	background: ${ colors.white };
	transition: max-width ${ animations.mediumSpeed } ease-in-out;
	${ ({ navCollapsed }) => navCollapsed ? `
		max-width: 121px;
		transition-delay: .2s;
	` : `
		max-width: 354px;
	` }
	${ mq.smallAndBelow } {
		max-width: 100%;
	}
`

const NavHeader = styled.div`
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 0 0 0 var(--h-padding);
	height: var(--row-height);
`

const MenuIndicator = styled.div<{ collapsed?: boolean }>`
	padding: 0 var(--h-padding);
	height: var(--row-height);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;
	position: relative;
	cursor: pointer;
	color: ${ colors.red };
	transition: margin ${ animations.mediumSpeed } ease-in-out;
	margin-right: -4px;
	${ mq.mediumAndUp } {
		margin-right: 0;
		${ ({ collapsed }) => !collapsed && `
			margin-right: -4px;
		` }
	}
	> span {
		margin-right: .5em;
		position: absolute;
		height: var(--row-height);
		display: flex;
		align-items: center;
		right: calc(100% - var(--h-padding));
		top: 0px;
		${ typography.smallCaps }
		transition: transform ${ animations.mediumSpeed } ease-in-out,
								opacity ${ animations.mediumSpeed } ease-in-out;
		${ mq.mediumAndUp } {
			${ ({ collapsed }) => collapsed ? `
				opacity: 0;
				transform: translateY(-10px);
			` : `
				transition-delay: .1s;
			` }
		}
	}
	svg {
		* {
			fill: currentcolor;
		}
		transition: transform ${ animations.mediumSpeed } ease-in-out,
								opacity ${ animations.mediumSpeed } ease-in-out;
		${ mq.mediumAndUp } {
			${ ({ collapsed }) => collapsed ? `
				opacity: 0;
				transform: translateY(-10px);
			` : `
				transition-delay: .1s;
			` }
		}
	}
`

const MenuIconWrapper = styled.div`
	width: 18px;
	height: 18px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`

const MenuIcon = styled.div<{ collapsed?: boolean }>`
	width: 18px;
	height: 12px;
	color: var(--text-color);
	border-top: 2px solid currentcolor;
	border-bottom: 2px solid currentcolor;
	position: absolute;
	top: 3px;
	left: 0;
	transition: transform ${ animations.mediumSpeed } ease-in-out,
							opacity ${ animations.mediumSpeed } ease-in-out;
	${ mq.smallAndBelow } {
		display: none;
	}
	${ ({ collapsed }) => !collapsed ? `
		opacity: 0;
		transform: translateY(10px);
	` : `
		transition-delay: .1s;
	` }
	&:hover {
		color: ${ colors.red };
	}
`

const LogoLink = styled(Link)`
	display: inline-flex;
	align-items: center;
	height: 100%;
	vertical-align: top;
	cursor: pointer;
	overflow: hidden;
	padding: 0 var(--h-padding);
	margin-left: calc(var(--h-padding) * -1);
`

const NavLogo = styled(Logo)<{ collapsed?: boolean }>`
	flex-shrink: 0;
	display: block;
	${ ({ collapsed }) => collapsed && `
		margin-left: -4px;
	` }
	${ mq.mediumAndBelow } {
		--logo-width: 82px;
	}
	${ mq.mediumAndUp } {
		display: none;
	}
`


const AnimatedNavLogo = styled.div<{ collapsed?: boolean }>`
	--logo-width: 98px;
	flex-shrink: 0;
	display: block;
	position: relative;
	width: var(--logo-width);
	height: calc(var(--logo-width) * 0.35);
	transition: transform .4s ease-in-out, margin .4s ease-in-out;
	transition-delay: .45s;
	${ mq.smallAndBelow } {
		display: none;
	}
	${ ({ collapsed }) => collapsed ? `
		transform: translateX(-37%);
		transition-delay: 0s;
		margin-top: calc(var(--logo-width) * -.03);
		margin-top: calc(var(--logo-width) * -.05);
	` : `
		margin-top: calc(var(--logo-width) * -.09);
	` }
	.cover {
		height: 40px;
		position: absolute;
		top: 100%;
		background: ${ colors.white };
		z-index: 2;
		&.cover-1 {
			left: -1px;
			width: 23%;
		}
		&.cover-2 {
			right: -1px;
			width: 28%;
		}
	}
	${ mq.mediumAndBelow } {
		--logo-width: 82px;
	}
	svg {
		flex-shrink: 0;
		flex-grow: 0;
		height: calc(var(--logo-width) * 0.59) !important;
	}
`

const NavContent = styled(AnimateHeight)<{
	expanded?: boolean | string,
	cardPanel?: boolean | string,
}>`
	${ ({ cardPanel }) => cardPanel ? `
		// height: 100vh !important;
	` : '' }
	max-height: calc(100vh - var(--site-margins) * 2 - var(--row-height));
`

const NavInner = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	transition: all ${ animations.mediumSpeed } ease-in-out;
	overflow: hidden;
	border-top: 1px solid ${ colors.sand };
	overflow-x: hidden !important;
	overflow-y: auto !important;
	max-height: calc(100vh - var(--site-margins) * 2 - var(--row-height));
`

const LinkList = styled.ul<{ cardsActive?: boolean | string }>`
	list-style: none;
	padding: 0;
	margin: 0;
	transition: right ${ animations.mediumSpeed } ease-in-out;
	width: 100%;
	${ ({ cardsActive }) => cardsActive ? `
		position: absolute;
		right: 100%;
	` : `
		position: relative;
		right: 0;
	` }
`

const CardsPanel = styled.div<{ active?: boolean }>`
	transition: left ${ animations.mediumSpeed } ease-in-out;
	top: 0;
	width: 100%;
	overflow: hidden;
	${ ({ active }) => active ? `
		margin-top: 0;
		position: relative;
		left: 0;
	` : `
		margin-top: var(--row-height);
		position: absolute;
		left: 100%;
		height: 0;
	` }
`

const CardList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	padding: 0 var(--h-padding);
`

const PlusIcon = styled.div<{ expanded?: boolean }>`
	width: 8px;
	height: 2px;
	background: currentcolor;
	position: relative;
	transition: transform ${ animations.mediumSpeed } ease-in-out;
	${ ({ expanded }) => expanded && `
		transform: rotate(-45deg);
	` }
	&:after {
		content: '';
		transition:	transform ${ animations.mediumSpeed } ease-in-out;
		display: block;
		position: absolute;
		width: 2px;
		height: 8px;
		background: currentcolor;
		top: 50%;
		left: 50%;
		margin: -4px 0 0 -1px;
		${ ({ expanded }) => expanded && `
			transform: rotate(-180deg);
		` }
	}
`

const LinkRow = styled.div<{ isChild?: boolean, expanded?: boolean, firstItem?: boolean, to?: string, as?: any }>`
	display: block;
	height: var(--row-height);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 var(--h-padding);
	${ typography.smallCaps };
	color: ${ colors.gold };
	cursor: pointer;
	.row-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		svg {
			width: 10px;
			height: auto;
			display: block;
			margin-right: .75em;
		}
	}
	${ ({ firstItem }) => !firstItem && `
		border-top: 1px solid ${ colors.sand };
	` }
	svg {
		* {
			fill: currentcolor;
		}
	}
	${ ({ expanded }) => expanded ? `
		color: ${ colors.red };
		background: ${ colors.offWhite };
	` : `
		color: ${ colors.gold };
	` }
	${ ({ isChild, expanded }) => isChild ? `
		background: ${ colors.offWhite };
		color: var(--text-color);
		padding-left: calc(var(--h-padding) * 2);
		&:hover {
			background: ${ colors.sand };
			svg {
				opacity: 1;
				transform: none;
			}
		}
	` : `
		&:hover {
			background: ${ colors.offWhite };
			color: ${ colors.red };
			svg {
				opacity: 1;
				transform: none;
			}
		}
	`}
`

const ArrowDownIcon = styled(ArrowDown)<{ expanded?: boolean }>`
	${ ({ expanded }) => expanded ? `transform: rotate(-180deg);` : '' }
`

const PanelBackButton = styled.button`
	appearance: none;
	border: none;
	outline: none;
	padding: 0;
	border-radius: 0;
	height: auto;
	background: transparent;
	padding: 20px;
	text-align: inherit;
	cursor: pointer;
	${ typography.smallCaps }
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: ${ colors.mainColor };
	&:hover {
		svg {
			transform: translate3d(-4px, 0, 0);
		}
	}
	svg {
		transition:	transform ${ animations.mediumSpeed } ease-in-out;
		display: block;
		margin-right: 8px;
		margin-top: -2px;
		* {
			fill: currentcolor;
		}
	}
`

const NavAssetCard = styled(AssetCard)`
	background: ${ colors.offWhite };
	margin-bottom: var(--h-padding);
	.card-title {
		font-family: ${ typography.secondaryFont };
	}
`

interface linkItem {
	title: string,
	slug: string,
	_id: string,
}

interface assetItem {
	title: string,
	svgFile: any,
	assetFile: any,
	_id: string,
}

interface navigationItem {
	_key: string,
	title: string,
	items: Array<linkItem>,
	assetItems: Array<assetItem>,
	type: string,
	slug?: string,
	link?: any
}

const MainNav = () => {
	const animationContainer = useRef(null)
	const { navigation } = useContext(AppContext)
	const [expanded, setExpanded] = useState(false)
	const [navCollapsed, setNavCollapsed] = useState<boolean>(true)
	const [expandedItem, setExpandedItem] = useState<string | boolean>(false)
	const [animationEl, setAnimationEl] = useState<any>()
	
	const [cardPanel, setCardPanel] = useState<string | boolean>(false)

	// When debugging
	// const [expanded, setExpanded] = useState(true)
	// const [navCollapsed, setNavCollapsed] = useState<boolean>(false)
	// const [expandedItem, setExpandedItem] = useState<string | boolean>(true)
	//

	const [navHeight, setNavHeight] = useState<string | number>(0)
	const [currentNavHeight, setCurrentNavHeight] = useState<string | number>(0)
	const [currentPanelHeight, setCurrentPanelHeight] = useState<string | number>(0)

	const toggleItem = (id?: string, cardList?: boolean) => {
		if (expandedItem === id) {
			setExpandedItem(false)
			setCardPanel(false)
		} else if (id) {
			setExpandedItem(id)
			if (cardList) {
				setCardPanel(id)
			}
		}
	}

	const toggleExpand = () => {
		if (expanded) {
			setNavCollapsed(false)
			setExpandedItem(false)
			setCardPanel(false)
		}
		setExpanded(!expanded)
	}

	const setNavWidth = (wide?: boolean) => {
		if (wide && navCollapsed) {
			setNavCollapsed(false)
		} else if (!navCollapsed) {
			setNavCollapsed(true)
			setExpanded(false)
			setExpandedItem(false)
		}
	}

	useEffect (() => {
		const anim = lottie.loadAnimation({
			container: animationContainer.current!,
			renderer: 'svg',
			loop: false,
			autoplay: false,
			animationData: animatedLogoJson
		})
		setAnimationEl(anim)
	}, [])

	useEffect (() => {
		if (animationEl) {
			const maxFrames = animationEl.totalFrames
			let frame = navCollapsed ? maxFrames : 0
			// if (frame < 1) {
			// 	frame = 1
			// }
			// if (frame >= animationEl.totalFrames) {
			// 	frame = animationEl.totalFrames
			// }
			if (navCollapsed) {
				animationEl.setDirection(-1)
			} else {
				animationEl.setDirection(1)
			}
			animationEl.play()
		}
	}, [navCollapsed])

	useEffect (() => {
	}, [expanded, cardPanel])

	const winWidth = useWindowWidth()

	return (
		<Wrapper
			onMouseEnter={() => setNavWidth(true)}
			onMouseLeave={() => setNavWidth(false)}
			navCollapsed={navCollapsed}
		>
			<NavBar
				navCollapsed={navCollapsed}
			>
				<NavHeader>
					<LogoLink to='/' title='Go to Homepage'>
						<NavLogo />
						<AnimatedNavLogo ref={animationContainer} collapsed={navCollapsed}>
							<div className='cover cover-1'/>
							<div className='cover cover-2'/>
						</AnimatedNavLogo>
					</LogoLink>
					<MenuIndicator collapsed={navCollapsed} onClick={toggleExpand}>
						<span>Menu</span>
						<MenuIconWrapper>
							<ArrowDownIcon expanded={expanded} />
							<MenuIcon collapsed={navCollapsed} />
						</MenuIconWrapper>
					</MenuIndicator>
				</NavHeader>
				<NavContent
					duration={300}
					delay={0}
					animateOpacity={false}
					height={!expanded ? 0 : 'auto'}
					expanded={expanded}
					onAnimationStart={(newHeight) => setCurrentNavHeight(newHeight.newHeight)}
				>
					<div>
						<NavInner>
							<LinkList cardsActive={cardPanel}>
								{navigation.map((item: navigationItem, index: number) => {
									let cards = false
									console.log(item)
									if (item?.assetItems && item?.assetItems?.length > 0) {
										cards = true
									}
									if (item?.link?.slug) {
										const { slug } = item?.link
										return (
											<li key={item._key}>
												<LinkRow
													to={slug === 'home' ? '/' : '/' + slug}
													firstItem={index === 0}
													as={Link}
												>
													<div className='row-title'>
														{item.title || item.link.title}
													</div>
													<ArrowRight/>
												</LinkRow>
											</li>
										)
									}
									return (
										<li key={item._key}>
											<LinkRow
												onClick={() => toggleItem('item' + index, cards)}
												expanded={expandedItem === 'item' + index}
												firstItem={index === 0}
												as='a'
											>
												<div className='row-title'>
													{cards && (
														<IconDownload />
													)}
													{item.title}
												</div>
												{!cards ? (
													<PlusIcon expanded={expandedItem === 'item' + index} />
												) : (
													<ArrowRight/>
												)}
											</LinkRow>
											{!cards && (
												<AnimateHeight
													className={expandedItem === 'item' + index ? 'content open' : 'content'}
													duration={300}
													delay={0}
													animateOpacity={false}
													height={expandedItem === 'item' + index ? 'auto' : 0}
												>
													<LinkList>
														{item.items && item.items.map((subItem: linkItem, itemIndex: number) => (
															<LinkRow
																to={subItem.slug === 'home' ? '/' : '/' + subItem.slug}
																key={subItem._id}
																isChild={true}
																as={Link}
																onClick={toggleExpand}
															>
																{subItem.title}
															</LinkRow>
														))}
													</LinkList>
												</AnimateHeight>
											)}
										</li>
									)
								})}
							</LinkList>

							{navigation.map((item: navigationItem, index: number) => {
								let cards = false
								if (item?.assetItems && item?.assetItems?.length > 0) {
									cards = true
								}
								if (!cards) {
									return false
								}
								const active = expandedItem === 'item' + index
								return (
									<CardsPanel
										key={'itemCards' + index}
										active={active}
									>
										<div>
											<PanelBackButton
												onClick={() => toggleItem('item' + index, cards)}
											>
												<ArrowLeft/> Back
											</PanelBackButton>
											{item?.assetItems?.length > 0 && (
												<CardList>
													<li>
														{item.assetItems.map((subItem: assetItem, itemIndex: number) => (
															<div key={subItem._id}>
																<NavAssetCard
																	title={subItem.title}
																	svgAsset={subItem.svgFile}
																	asset={subItem.assetFile}
																	color='sand2'
																	cardHeight='short'
																/>
															</div>
														))}
													</li>
												</CardList>
											)}
										</div>
									</CardsPanel>
								)
							})}
						</NavInner>
					</div>
				</NavContent>
			</NavBar>
		</Wrapper>
	)
}

export default MainNav
