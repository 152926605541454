import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { AppContext } from 'state/AppState'
import ModuleRenderer from 'components/ModuleRenderer'
import SoftFooter from 'components/SoftFooter'

const Page = () => {
	const [pageData, setPageData] = useState<any>()
	const { pages } = useContext(AppContext)
	const { section, slug } = useParams()

	let pageSlug = slug || 'home'
	if (section) {
		pageSlug = section + '/' + slug
	}

	useEffect(() => {
		if (pages) {
			getPage()
		}
	}, [pages, pageSlug])

	useEffect(() => {
		document.body.scrollTop = 0;
	  document.documentElement.scrollTop = 0;
	}, [pageSlug])

	const getPage = () => {
		if (pages.length > 0 && pageSlug) {
			const currentPage = pages.filter((page: any) => page?.slug?.current === pageSlug)[0]
			setPageData(currentPage)
		} else {
			const currentPage = pages.filter((page: any) => page?.slug?.current === 'home')[0]
			setPageData(currentPage)
		}
	}

	if (!pageData) {
		return null
	}

	const modules = pageData?.modules || []

	return (
		<div style={{ position: 'relative', zIndex: 1 }} key={pageSlug}>
			{modules && modules?.length > 0 && modules.map((module: any, index: number) => {
				const prevModule = modules[index - 1]
				const nextModule = modules[index + 1]
				let prevTheme = null
				let nextTheme = null
				if (prevModule) {
					prevTheme = 'default'
					if (prevModule.theme) {
						prevTheme = prevModule.theme
					}
					if (prevModule._type === 'horizontalDrag') {
						prevTheme = 'sand'
					}
					if (prevModule._type === 'homeAtf') {
						prevTheme = prevModule.btf.btfTheme
					}
				}
				if (nextModule) {
					nextTheme = 'default'
					if (nextModule.theme) {
						nextTheme = nextModule.theme
					}
					if (nextModule._type === 'horizontalDrag') {
						nextTheme = 'sand'
					}
					if (nextModule._type === 'homeAtf') {
						nextTheme = 'default'
					}
				}
				return (
					<ModuleRenderer
						key={module._key}
						module={module}
						modules={modules}
						prevTheme={prevTheme}
						nextTheme={nextTheme}
						isFirstSection={index === 0}
						isLastSection={index === modules.length - 1}
					/>
				)
			})}

			<SoftFooter
				currentPage={pageSlug}
			/>
		</div>
	)
}

export default Page
