import React, { useContext } from 'react'
import { AppContext } from 'state/AppState'
import { getFileAsset } from '@sanity/asset-utils'
import imageUrlBuilder from '@sanity/image-url'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ReactComponent as ArrowDown } from 'assets/images/arrow-stem-down.svg'
import { animations, typography, colors } from 'styles'
import SvgAsset from 'components/SvgAsset'

const sanityOptions = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
}

const DownloadIconWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transform-style: preserve3d;
  flex-grow: 0;
  flex-shrink: 0;
  transition: color ${ animations.mediumSpeed } ease-in-out;
  &:after {
    content: '';
    display: block;
    width: 9px;
    height: 2px;
    background: currentcolor;
    margin: 3px auto 0;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
  }
  svg {
    &:first-of-type {
      opacity: 0;
    }
    display: block;
    overflow: hidden;
    height: 13px;
    width: 13px;
    * {
      fill: currentcolor;
    }
  }
`

const AssetPreview = styled.div<{ color?: string }>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  img {
    width: 30%;
    height: 30%;
    max-height: 60px;
    max-width: 60px;
    object-fit: contain;
  }
  svg {
    display: block;
    width: 30%;
    height: 30%;
    max-height: 60px;
    max-width: 60px;
    rect, path, circle, ellipse, polygon {
      &:not([fill="none"]) {
        fill: ${ ({ color }) => color ? colors[color] : colors.gold };
      }
    }
  }
`

const Wrapper = styled.a<{ className?: string }>`
  background: var(--card-bg);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 100%;
  position: relative;
  height: 100%;
  ${ typography.body }
  &:hover {
    .download-icon {
      color: var(--main-color);
      svg {
        transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
        transform: translate3d(0, 100%, 0);
        &:first-of-type {
          opacity: 1;
        }
        &:last-of-type {
          opacity: 1;
          transform: translate3d(0, 120%, 0);
        }
      }
      &:after {
        transform: scaleX(1.5);
      }
    }
  }
`

const CardFooter = styled.div<{ position?: string }>`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 10px;
  ${ ({ position }) => position === 'top' && `
    margin-bottom: 30%;
  ` }
`

const CardTitle = styled.div`
  ${ typography.bodyTiny }
  line-height: 1em;
`

type AssetCardProps = {
  className?: string,
  title?: any,
  svgAsset?: any,
  asset?: any,
  color?: string
}

const AssetCardSmall = ({ className = '', asset, svgAsset, title, color = 'gold' }: AssetCardProps) => {
  const { sanity } = useContext(AppContext)
  const urlFor = (source: any) => {
    return imageUrlBuilder(sanity).image(source)
  }

  if (!svgAsset || !title) {
    return null
  }

  const fileUrl = asset ? getFileAsset(asset?._ref, sanityOptions)?.url : ''
  const svgUrl = urlFor(svgAsset).url() || ''
  const downloadUrl = fileUrl || svgUrl

  console.log(downloadUrl)

  return (
    <Wrapper
      className={className}
      href={downloadUrl}
      title={'Download ' + title}
      target='_blank'
      rel='noopener noreferrer'
    >
      <AssetPreview color={color}>
        {color === 'auto' ? (
          <img src={ downloadUrl } alt={title} />
        ) : (
          <SvgAsset asset={svgAsset} />
        )}
      </AssetPreview>
      <CardFooter>
        <CardTitle>{title}</CardTitle>
        <DownloadIconWrapper className='download-icon'>
          <ArrowDown/>
          <ArrowDown/>
        </DownloadIconWrapper>
      </CardFooter>
    </Wrapper>
  )
}

export default AssetCardSmall
