import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from 'state/AppState'
import imageUrlBuilder from '@sanity/image-url'
import SVG from 'react-inlinesvg'
import styled from '@emotion/styled'

const Wrapper = styled.div<{ className?: string }>``

type SvgAssetProps = {
  asset?: object
}

const SvgAsset = ({ asset }: SvgAssetProps) => {
  const { sanity } = useContext(AppContext)
  const urlFor = (source: any) => {
    return imageUrlBuilder(sanity).image(source)
  }
  const srcUrl = urlFor(asset).url()

  if (!srcUrl) {
    return null
  }

  return (
    <SVG src={srcUrl} />
  )
}

export default SvgAsset