import React from 'react'
// plopImportModules
import LottieScroll from 'components/LottieScroll'
import TypographyHierarchy from 'components/TypographyHierarchy'
import WideCards from 'components/WideCards'
import HomeAtf from 'components/HomeAtf'
import ScrollToNav from 'components/ScrollToNav'
import ColorPalette from 'components/ColorPalette'
import Slideshow from 'components/Slideshow'
import WideMedia from 'components/WideMedia'
import StickyNumberChart from 'components/StickyNumberChart'
import NumberSystem from 'components/NumberSystem'
import HorizontalDrag from 'components/HorizontalDrag'
import AssetGrid from 'components/AssetGrid'
import MediaGrid from 'components/MediaGrid'
import TextSection from 'components/TextSection'
import PageIntro from 'components/PageIntro'
import HorizontalContent from 'components/HorizontalContent'
import StickyCards from 'components/StickyCards'
import TwoColumnText from 'components/TwoColumnText'

interface componentInfo {
  [x: string]: any
}

const componentMap: componentInfo = {
	twoColumnText: TwoColumnText,
  // plopAddModules
	lottieScroll: LottieScroll,
	typographyHierarchy: TypographyHierarchy,
	wideCards: WideCards,
	homeAtf: HomeAtf,
	scrollToNav: ScrollToNav,
	colorPalette: ColorPalette,
	slideshow: Slideshow,
	wideMedia: WideMedia,
	stickyNumberChart: StickyNumberChart,
	numberSystem: NumberSystem,
	horizontalDrag: HorizontalDrag,
	assetGrid: AssetGrid,
	mediaGrid: MediaGrid,
	textSection: TextSection,
	pageIntro: PageIntro,
	horizontalContent: HorizontalContent,
	stickyCards: StickyCards,
}

type ModuleRendererProps = {
  module: any,
  modules: Array<any>,
  prevTheme?: string,
  nextTheme?: string,
  isFirstSection?: boolean,
	isLastSection?: boolean
}

const ModuleRenderer = ({ module, modules, prevTheme, nextTheme, isFirstSection, isLastSection }: ModuleRendererProps) => {
  // const module = module
  if (!module || !module?._type) {
    return <></>
  }
  const Component = componentMap[module._type]
  return Component ? (
    <Component
      {...module}
      modules={modules}
      prevTheme={prevTheme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
    />
  ) : (
    <div><h3>TODO: {module.module?._type}</h3></div>
  )
}

export default ModuleRenderer