import React from 'react'
import styled from '@emotion/styled'
import MaterialIcon from 'components/MaterialIcon'
import { typography, colors, util, animations } from 'styles'
import { uiElementSizes, responsiveUiSizes } from 'styles/globals'
import { buttonThemes } from 'styles/themes'

interface themeData {
  [x: string]: any
}

const themes: themeData = buttonThemes

const StyledButton = styled.div<{
  as?: any,
  shape?: string,
  size?: string,
  title?: string,
  setTheme: string,
  disabled?: boolean,
  to?: string,
  smooth?: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${ ({ setTheme }) => `
    /* Set Theme Colors */
    --text-color: ${ setTheme ? themes[setTheme].color : themes.default.color };
    --bg-color: ${ setTheme ? themes[setTheme].background : themes.default.background };
    --border-color: var(--bg-color);
    --shadow: none;
    /* Hover Colors */
    --text-color-hover: ${ setTheme ? themes[setTheme].hoverColor : themes.default.hoverColor };
    --bg-color-hover: ${ setTheme ? themes[setTheme].hoverBackground : themes.default.hoverBackground };
    --border-color-hover: var(--bg-color-hover);
    --shadow-hover: none;
  ` }
  appearance: none;
  color: var(--text-color);
  background: var(--bg-color);
  border: 1px solid var(--bg-color);
  box-shadow: var(--shadow);
  transition: background ${ animations.mediumSpeed } ease-in-out,
              color ${ animations.mediumSpeed } ease-in-out,
              border ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out,
              box-shadow ${ animations.mediumSpeed } ease-in-out;
  ${ ({ size = 'medium' }) => util.responsiveStyles('height', uiElementSizes[size] * responsiveUiSizes.huge, uiElementSizes[size] * responsiveUiSizes.large, uiElementSizes[size] * responsiveUiSizes.medium, uiElementSizes[size] * responsiveUiSizes.small) }
  ${ ({ shape, size = 'medium' }) => shape === 'circle' ? `
    border-radius: 50%;
    padding: 0;
    ${ util.responsiveStyles('width', uiElementSizes[size] * responsiveUiSizes.huge, uiElementSizes[size] * responsiveUiSizes.large, uiElementSizes[size] * responsiveUiSizes.medium, uiElementSizes[size] * responsiveUiSizes.small) }
  ` : `
    border-radius: 10px;
    padding: 0 ${ uiElementSizes[size] * .4 }px;
  ` }
  ${ ({ disabled }) => disabled ? `
    cursor: default;
    pointer-events: none;
  ` : `
    cursor: pointer;
    &:hover {
      color: var(--text-color-hover);
      background: var(--bg-color-hover);
      border-color: var(--border-color-hover);
      box-shadow: var(--shadow-hover);
    }
  ` }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonLabel = styled.div`
  ${ typography.smallCaps }
  line-height: 1em;
  white-space: nowrap;
  flex-shrink: 0;
`

const ButtonIcon = styled.div<{ position?: string, shape?: string, size?: string, children: any, iconSize?: string }>`
  --icon-size: ${ ({ iconSize }) => iconSize };
  flex-shrink: 0;
  flex-grow: 0;
  svg {
    width: var(--icon-size);
    height: var(--icon-size);
  }
`

const renderIcon = (icon: any, position?: string, shape?: string, size?: string) => {
  let renderedIcon = null
  interface sizeData {
    [x: string]: any
  }
  if (icon) {
    if (!size) {
      size = 'medium'
    }
    const iconSizes: sizeData = {
      tiny: '18px',
      small: '18px',
      medium: '24px',
      large: '24px',
      huge: '36px'
    }
    if (typeof icon === 'string') {
      renderedIcon = <ButtonIcon className='button-icon' size={size} iconSize={iconSizes[size]} position={position} shape={shape}><MaterialIcon size={iconSizes[size]}>{icon}</MaterialIcon></ButtonIcon>
    } else {
      renderedIcon = <ButtonIcon className='button-icon' size={size} iconSize={iconSizes[size]} position={position} shape={shape}>{icon}</ButtonIcon>
    }
  }
  return renderedIcon
}

type ButtonProps = {
  className?: string,
  setTheme?: string,
  children?: any,
  icon?: any,
  iconPosition?: 'left' | 'right',
  shape?: string,
  size?: string,
  title?: string,
  disabled?: boolean,
  onClick?: () => void,
  as?: any,
  to?: string
}

export const Button = ({
  className = '',
  setTheme = 'default',
  children,
  icon,
  iconPosition = 'left',
  shape = 'normal',
  size = 'medium',
  title,
  disabled,
  onClick,
  as,
  to,
  ...props
}: ButtonProps) => {
	return (
		<StyledButton
      as={as || 'button'}
      to={to}
      shape={shape}
      size={size}
      title={title}
      aria-label={title}
      setTheme={setTheme}
      className={'button ' + className}
      onClick={onClick}
      disabled={disabled}
      smooth={true}
      {...props}
    >
      <ButtonContent>
        {icon && iconPosition !== 'right' && (
          <ButtonIcon size={size}>
            {renderIcon(icon, iconPosition, shape, size)}
          </ButtonIcon>
        )}
        {shape !== 'circle' && shape !== 'square' && (
          <ButtonLabel>
            {children}
          </ButtonLabel>
        )}
        {icon && iconPosition === 'right' && (
          <ButtonIcon size={size}>
            {renderIcon(icon, iconPosition, shape, size)}
          </ButtonIcon>
        )}
      </ButtonContent>
    </StyledButton>
	)
}

export default Button
