import React from 'react'
import styled from '@emotion/styled'
import * as Scroll from 'react-scroll'
import Grid from 'components/Grid'
import Section from 'components/Section'
import Button from 'components/Button'
import { mq } from 'styles'

const Wrapper = styled(Grid)``

const NavItems = styled.div`
	margin-bottom: 30px;
	overflow-x: auto;
	white-space: nowrap;
`

const NavButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: var(--site-margins);
	${ mq.mediumAndUp } {
		padding-left: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
	}
	.button-wrap {
		flex-shrink: 0;
		&:not(:last-of-type) {
			margin-right: 10px;
		}
		&:last-of-type {
			margin-right: 10px;
			padding-right: var(--site-margins);
			${ mq.mediumAndUp } {
				padding-right: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			}
		}	
	}
`

type ScrollToNavProps = {
  className?: string,
  modules?: Array<any>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean,
	_key?: string
}

const ScrollToNav = ({
	className = '',
	modules,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: ScrollToNavProps) => {
	let scrollToModules = modules?.filter(module => module._type === 'textSection' || module._type === 'stickyCards' || module._type === 'horizontalDrag')
	scrollToModules = scrollToModules?.filter(module => module?.navTitle)
	if (!scrollToModules || scrollToModules.length < 1) {
		return false
	}
	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				margins
			>
				<div>
					<h6>Quick Links</h6>
				</div>
			</Wrapper>
			<NavItems>
				<NavButtons>
					{scrollToModules && scrollToModules.map((item) => {
						let buttonTitle = item.navTitle || item.headline || item.title
						if (!buttonTitle) {
							buttonTitle = item?.secondaryContent?.secondaryHeadline
						}
						if (!buttonTitle) {
							return null
						}
						return (
							<div key={item._key} className='button-wrap'>
								<Button
									size='small'
									as={Scroll.Link}
									to={item._key}
									setTheme='sand'
								>{buttonTitle}</Button>
							</div>
						)
					})}
				</NavButtons>
			</NavItems>
		</Section>
	)
}

export default ScrollToNav
