import React, { useState } from 'react'
import styled from '@emotion/styled'
import Section from 'components/Section'
import Grid from 'components/Grid'
import { ReactComponent as IconClipboard } from 'assets/images/icon-clipboard.svg'
import { colors as uiColors, typography, mq, animations, util } from 'styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'

interface colorData {
  [x: string]: any
}

const Wrapper = styled(Grid)``

const SectionHeader = styled.div`
	display: flex;
`

const ColorItem = styled.div<{ bgColor?: 'string', textColor?: 'string', onClick?: any }>`
	appearance: none;
	border: none;
	outline: none;
	position: relative;
	background: ${ ({ bgColor }) => bgColor };
	color: ${ ({ textColor }) => textColor };
	text-align: left;
	border: 1px solid ${ ({ bgColor = 'white' }) => bgColor.toLowerCase() === uiColors.bgColor.toLowerCase() ? uiColors.gold : bgColor };
	${ typography.body }
	padding: calc(var(--site-gutters) * .5);
	cursor: pointer;
	${ mq.mediumAndUp } {
		padding: var(--site-gutters);
	}
	${ mq.largeAndUp } {
		display: flex;
		align-items: baseline;
	}
	.color-left {
		display: flex;
		align-items: baseline;
		flex-grow: 1;
		justify-content: space-between;
		margin-bottom: 40px;
		${ mq.largeAndUp } {
			margin-bottom: 0;
			${ util.responsiveStyles('margin-top', 100, 80, 70, 60) }
			justify-content: flex-start;
		}
		h6 {
			${ mq.largeAndUp } {
				width: 50%;
			}	
		}
	}
	.color-right {
		flex-grow: 0;
		padding-right: 80px;
		p {
			max-width: 10em;
			${ mq.mediumAndUp } {
				max-width: none;
			}
		}
		${ mq.largeAndUp } {
			padding-right: 0;
		}
	}
	h6, p {
		margin: 0;
	}
	&:hover {
		.copy-icon {
			opacity: 1;
		}
	}
`

const CopyIcon = styled.div<{ className?: string }>`
	display: flex;
	align-items: center;
	color: inherit;
	${ typography.body }
	position: absolute;
	bottom: calc(var(--site-gutters) * .5);
	right: calc(var(--site-gutters) * .5);
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
	${ mq.mediumAndUp } {
		bottom: var(--site-gutters);
		right: var(--site-gutters);
	}
	${ mq.largeAndUp } {
		top: var(--site-gutters);
		bottom: unset;
		opacity: 0;
	}
	svg {
		display: block;
		margin-right: 6px;
		* {
			fill: currentcolor;
		}
	}
`

type ColorPaletteProps = {
  className?: string,
  title: string,
  colors: Array<colorData>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const ColorPalette = ({
	className = '',
	title,
	colors,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: ColorPaletteProps) => {
	const [copied, setCopied] = useState('')
	if (!colors || colors.length < 1) {
		return null
	}

	const copyColorCode = (key: string) => {
		setCopied(key)
		setTimeout(() => {
			setCopied('')
		}, 3000)
	}

	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				margins
			>
				{title && (
					<SectionHeader>
						<h6>{title}</h6>
					</SectionHeader>
				)}
				<Grid
					small='[1]'
					rowGap='var(--site-gutters)'
				>
					{colors.map((color) => (
						<CopyToClipboard
							text={color.colorValue.hex}
						>
							<ColorItem
								color={color._key}
								bgColor={color.colorValue.hex}
								textColor={color.textColor}
								onClick={() => copyColorCode(color._key)}
							>
								<CopyIcon className='copy-icon'><IconClipboard/> {copied === color._key ? 'Copied' : 'Copy'}</CopyIcon>
								<div className='color-left'>
									<h6>{color.colorTitle}</h6>
									<p style={{ textTransform: 'uppercase' }}>{color.colorValue.hex}</p>
								</div>
								<div className='color-right'>
									<p>Use "{color.textColor}" text on top of this color.</p>
								</div>
							</ColorItem>
						</CopyToClipboard>
					))}
				</Grid>
			</Wrapper>
		</Section>
	)
}

export default ColorPalette
