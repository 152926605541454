import React, { useState } from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import Section from 'components/Section'
import Media from 'components/Media'
import Button from 'components/Button'
import MaterialIcon from 'components/MaterialIcon'
import { animations, mq } from 'styles'

const Wrapper = styled(Grid)``

const PlayButton = styled(Button)`
	position: absolute;
	bottom: 20px;
	left: 20px;
	${ mq.largeAndBelow } {
		width: 80px;
		height: 80px;
	}
	${ mq.mediumAndBelow } {
		width: 50px;
		height: 50px;
		.icon {
			font-size: 24px;
		}
	}
`

const SoundButton = styled(Button)<{ setTheme?: string }>`
	position: absolute;
	bottom: 20px;
	right: 20px;
	${ mq.mediumAndBelow } {
		width: 32px;
		height: 32px;
		.icon {
			font-size: 18px;
		}
	}
`

const SoundIcon = styled.div<{ muted?: boolean }>`
	display: flex;
	position: relative;
	transition: transform ${ animations.mediumSpeed } ease-in-out;
	${ ({ muted }) => muted ? `
		transform: translate3d(-6px, 0, 0);
	` : `
		transform: none;
	` }
	.x-icon {
		position: absolute;
		left: 1.1em;
		top: .25em;
		font-size: 16px;
		${ mq.mediumAndBelow } {
			font-size: 12px;
		}
		transition: opacity ${ animations.mediumSpeed } ease-in-out,
								transform ${ animations.mediumSpeed } ease-in-out;
		${ ({ muted }) => muted ? `
			opacity: 1;
			transform: none;
		` : `
			opacity: 0;
			transform: rotate(-45deg);
		` }
	}
`

interface mediaData {
	mediaType: 'image' | 'video',
	image: any,
	video: any
}

type WideMediaProps = {
  className?: string,
  media: mediaData,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const WideMedia = ({
	className,
	media,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: WideMediaProps) => {
	const [muted, setMuted] = useState(true)
	const [playing, setPlaying] = useState(true)
	if (!media) {
		return null
	}
	const isVideo = media?.mediaType === 'video'
	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				margins
			>
				<div style={{ position: isVideo ? 'relative' : 'static' }}>
					<Media
						media={media}
						video={{
							playing: playing,
							muted: muted
						}}
					/>
					{isVideo && (
						<>
							<PlayButton
								shape='circle'
								icon={playing ? 'pause' : 'play_arrow'}
								size='huge'
								title='Play'
								onClick={() => setPlaying(!playing)}
							/>
							<SoundButton
								shape='circle'
								icon={
									<SoundIcon muted={muted}>
										<MaterialIcon size='24px'>volume_mute</MaterialIcon>
										<MaterialIcon className='x-icon'>close</MaterialIcon>
									</SoundIcon>
								}
								title='Pause'
								setTheme='white'
								onClick={() => setMuted(!muted)}
							/>
						</>
					)}
				</div>
			</Wrapper>
		</Section>
	)
}

export default WideMedia
