import React from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import { typography, colors, mq } from 'styles'
import Section from 'components/Section'
import SanityRichText from 'components/SanityRichText'
import { ReactComponent as MarkerIcon } from 'assets/images/marker-icon.svg'

const Wrapper = styled(Grid)``

const LeftColumn = styled.div<{showMarker?: boolean}>`
	position: relative;
	${ typography.smallCaps }
	color: ${ colors.gold };
	${ mq.largeAndBelow } {
		${ ({ showMarker }) => showMarker && `
			margin-top: 26px;
		` }
	}
`

const RightColumn = styled.div`
	h3 {
		${ typography.h1 }
	}
	p {
		${ typography.bodyMedium }
	}
`

const Marker = styled(MarkerIcon)`
	position: absolute;
	top: -26px;
	left: 0;
	width: 12px;
	height: auto;
	* {
		fill: ${ colors.red };
	}
`

type TwoColumnTextProps = {
	className?: string,
	leftText?: string,
	showMarker?: boolean,
	rightText: Array<object>,
	nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const TwoColumnText = ({
	className = '',
	leftText,
	showMarker,
	rightText,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: TwoColumnTextProps) => (
	<Section
		nextTheme={nextTheme}
		prevTheme={prevTheme}
		isFirstSection={isFirstSection}
		isLastSection={isLastSection}
	>
		<Wrapper
			className={className}
			small='[1]'
			medium='1 [12] 1'
			colGap='var(--site-gutters)'
			margins
		>
			<div>
				<Grid
					small='[1]'
					large='[1] [1]'
					colGap='var(--site-gutters)'
					vAlign='top'
					rowGap='var(--site-margins)'
				>
					<LeftColumn showMarker={showMarker}>
						{showMarker && <Marker/>}
						{leftText}
					</LeftColumn>
					<RightColumn>
						<SanityRichText text={rightText} />
					</RightColumn>
				</Grid>
			</div>
		</Wrapper>
	</Section>
)

export default TwoColumnText
