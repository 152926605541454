import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import ThemeSelector from 'components/ThemeSelector'
import NoomPath from 'components/NoomPath'
import Media from 'components/Media'
import { mq, typography, animations } from 'styles'

const Wrapper = styled(Grid)`
	${ mq.largerAndUp } {
		min-height: 100vh;
	}
`

const LeftColumn = styled.div<{ entered?: boolean }>`
	padding-top: calc(50px + 52px + var(--site-margins));
	padding-bottom: 50px;
	.transition-in {
		> * {
			transition: opacity ${ animations.slowSpeed } cubic-bezier(0.44, 0.24, 0.16, 1.00),
									transform ${ animations.slowSpeed } cubic-bezier(0.44, 0.24, 0.16, 1.00);
			${ ({ entered }) => entered ? `
				opacity: 1;
				transform: none;
			` : `
				opacity: 0;
				transform: translate3d(0, 30px, 0);
			` }
		}
		h1 {
			transition-delay: .05s;
		}
		p {
			&:nth-of-type(2) {
				transition-delay: .1s;
			}
		}
	}
	${ mq.largerAndUp } {
		padding: 50px 0;
	}
	p {
		max-width: 30em;
	}
`

const RightColumn = styled.div`
	position: relative;
	margin-left: calc(var(--site-margins) * -1);
	margin-right: calc(var(--site-margins) * -1);
	${ mq.largerAndUp } {
		margin-left: 0;
		height: 100%;
	}
`

const SectionMedia = styled(Media)`
	width: 100%;
	height: 100%;
	.image-wrapper,
	.video-wrapper {
		height: 100%;
		video {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		.ratio-setter {
			display: none;
		}
		${ mq.largeAndBelow } {
			height: 100vw;
		}
	}
`

const Eyebrow = styled.p`
	${ typography.smallCaps }
`

const OverlayWrapper = styled.div<{ entered?: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
	transform-style: preserve-3d;
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
	${ ({ entered }) => entered ? `
		opacity: 1;
	` : `
		opacity: 0;
	` }
`

const OverlayPath = styled(NoomPath)<{pathType: string}>`
	position: absolute;
	// Overlay 1
	${ ({ pathType }) => pathType === 'overlay1-1' && `
		right: 0;
		top: calc(var(--col-width) / 2);
		left: calc(var(--col-width) + var(--site-gutters));
		bottom: 0;
	` }
	${ ({ pathType }) => pathType === 'overlay1-2' && `
		right: 0;
		top: calc(var(--col-width) / 2);
		left: 0;
	` }

	// Overlay 2
	${ ({ pathType }) => pathType === 'overlay2-1' && `
		right: 50%;
		top: calc(50% - 1px - var(--path-width) / 2);
		left: calc(var(--col-width) + var(--site-gutters));
		bottom: 0;
	` }
	${ ({ pathType }) => pathType === 'overlay2-2' && `
		left: 50%;
		bottom: calc(50% - 1px - var(--path-width) / 2);
		right: calc(var(--col-width) + var(--site-gutters));
		top: 0;
	` }

	// Overlay 3
	${ ({ pathType }) => pathType === 'overlay3-1' && `
		left: 0;
		bottom: 0;
		width: 20vw;
		height: 20vw;
		width: calc(var(--col-width) * 4 + var(--site-gutters) * 3);
		height: calc(var(--col-width) * 4 + var(--site-gutters) * 3);
		border-top-right-radius: 100%;
		> div.inner-track {
			border-top-right-radius: 100%;
		}
	` }
	${ ({ pathType }) => pathType === 'overlay3-2' && `
		right: 0;
		top: 0;
		width: calc(var(--col-width) * 4 + var(--site-gutters) * 3);
		height: calc(var(--col-width) * 4 + var(--site-gutters) * 3);
		border-bottom-left-radius: 100%;
		> div.inner-track {
			border-bottom-left-radius: 100%;
		}
	` }
`

const renderOverlay = (pathType?: string, entered?: boolean) => {
	if (!pathType || pathType === 'none') {
		return false
	}

	interface pathConfigData {
	  [x: string]: any
	}

	// Configure path directions
	const pathConfig: pathConfigData = {
		overlay1: {
			path1: {
				right: false,
				bottom: false,
			},
			path2: {
				left: false,
				right: false,
				bottom: false
			}
		},
		overlay2: {
			path1: {
				right: false,
				bottom: false
			},
			path2: {
				left: false,
				top: false
			}
		},
		overlay3: {
			path1: {
				left: false,
				bottom: false
			},
			path2: {
				right: false,
				top: false
			}
		}
	}

	return (
		<OverlayWrapper entered={entered}>
			<OverlayPath
				pathType={pathType + '-1'}
				{...pathConfig[pathType].path1}
			/>
			<OverlayPath
				pathType={pathType + '-2'}
				{...pathConfig[pathType].path2}
			/>
		</OverlayWrapper>
	)
}

type PageIntroProps = {
  className?: string,
  theme: string,
  title: string,
  text?: string,
  eyebrow?: string,
  overlayType: string,
  media: object
}

const PageIntro = ({
	className,
	theme,
	title,
	text,
	eyebrow,
	overlayType,
	media
}: PageIntroProps) => {
	const [entered, setEntered] = useState(false)
	useEffect(() => {
		setEntered(true)
	}, [])
	return (
		<ThemeSelector setTheme={theme}>
			<Wrapper
				className={className}
				small='[1]'
				large='1 [4] 1 [8]'
				colGap='var(--site-gutters)'
				margins
				vAlign='center'
			>
				<LeftColumn entered={entered}>
					<Grid small='[1]' medium='1 [12] 1' large='[1]' colGap='var(--site-gutters)'>
						<div className='transition-in'>
							{eyebrow && (<Eyebrow>{eyebrow}</Eyebrow>)}
							<h1 className='h3'>{title}</h1>
							{text && (<p>{text}</p>)}
						</div>
					</Grid>
				</LeftColumn>
				<RightColumn>
					<SectionMedia
						media={media}
						image={{
							loading: 'eager'
						}}
					/>
					{renderOverlay(overlayType, entered)}
				</RightColumn>
			</Wrapper>
		</ThemeSelector>
	)
}

export default PageIntro
