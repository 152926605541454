import React, { useRef } from 'react'
import useDragScroll from 'use-drag-scroll'
import styled from '@emotion/styled'
import Section from 'components/Section'
import ThemeSelector from 'components/ThemeSelector'
import Grid from 'components/Grid'
import Image from 'components/Image'
import Video from 'components/Video'
import StarHeadline from 'components/StarHeadline'
import { typography, mq } from 'styles'
import { getImageDimensions, getFileAsset } from '@sanity/asset-utils'
import CursorDragPng from 'assets/images/cursor-drag.png'
import CursorDragSvg from 'assets/images/cursor-drag.svg'

const DragContainer = styled.div`
	overflow-x: scroll;
	white-space: nowrap;
	-ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
	  display: none;
	}
	${ mq.largeAndUp } {
		cursor: url('${ CursorDragSvg }') 40 40, url('${ CursorDragPng }') 40 40, grab;
		&:active {
			cursor: url('${ CursorDragSvg }') 40 40, url('${ CursorDragPng }') 40 40, grabbing;
		}
	}
`

const Item = styled.div<{ firstItem?: boolean, lastItem?: boolean }>`
	flex-shrink: 0;
	white-space: normal;
	--padding-width: calc((var(--100vw) - (var(--site-margins) * 2) - (var(--site-gutters) * 2)) / 14);
	h6, p {
		max-width: 500px;
		${ mq.largeAndUp } {
			max-width: 300px;
		}
	}
	p {
		${ typography.bodySmall }
	}
	margin-bottom: var(--site-gutters);
	margin-left: var(--site-gutters);
	margin-right: var(--site-gutters);
	${ mq.mediumAndUp } {
		padding: 0 var(--padding-width);
	}
	${ mq.largeAndUp } {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
	${ ({ firstItem }) => !firstItem ? `
		${ mq.largeAndUp } {
			margin-right: 0;
			margin-left: 60px;
		}
	` : `
		${ mq.largeAndUp } {
			padding-left: calc(var(--col-width) + var(--site-margins) + var(--site-gutters));
		}
	` }
	${ ({ lastItem }) => lastItem && `
		${ mq.largeAndUp } {
			padding-right: calc(var(--col-width) + var(--site-margins) + var(--site-gutters));
		}
	` }
`

const VerticalImage = styled(Image)<{ ratio?: number }>`
	${ mq.largeAndUp } {
		height: 100%;
		width: ${ ({ ratio }) => `calc(var(--media-height) * ${ ratio })`};
	}
`

const DragContent = styled.div`
	padding: var(--vertical-spacing) 0;
	${ mq.largeAndUp } {
		padding: var(--vertical-spacing) 0;
		--section-padding: var(--vertical-spacing);
		--media-height: calc(80vh - var(--section-padding) * 2);
		padding-top: var(--section-padding);
		padding-bottom: var(--section-padding);
		min-height: 80vh;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
`

const SectionIntro = styled(Grid)`
	padding-top: var(--vertical-spacing);
	margin-bottom: calc(var(--vertical-spacing) * -.5);
`

interface sectionData {
	[x: string]: any
}

type HorizontalDragProps = {
	className?: string,
	sections: Array<sectionData>,
	nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean,
	title?: string,
	subtitle?: string,
	_key: string,
	navTitle?: string
}

const client = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
  useCdn: true,
}

const HorizontalDrag = ({
	className,
	sections,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection,
	title,
	subtitle,
	_key,
	navTitle
}: HorizontalDragProps) => {
	const ref = useRef(null)
	useDragScroll({
		sliderRef: ref,
		momentumVelocity: 0.9
	})
	return (
		<Section
			theme='sand'
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
			id={_key}
		>
			<ThemeSelector setTheme='sand'>
				<SectionIntro margins={true} small='[1]' medium='1 [12] 1' colGap='var(--site-gutters)'>
					<div>
						{navTitle && (<StarHeadline>{navTitle}</StarHeadline>)}
						{title && (<h3>{title}</h3>)}
						{subtitle && (<p>{subtitle}</p>)}
					</div>
				</SectionIntro>

				<DragContainer ref={ref}>
					<DragContent>
						{sections.map((section: any, index: number) => {
							switch (section._type) {
								case ('image'):
									return (
										<Item key={section._key} firstItem={index === 0} lastItem={index === sections.length - 1}>
											<VerticalImage image={section.asset} ratio={getImageDimensions(section.asset).aspectRatio} />
										</Item>
									)

								case ('video'):
									return (
										<Item key={section._key} firstItem={index === 0} lastItem={index === sections.length - 1}>
											<Video video={section} />
										</Item>
									)

								case ('textSection'):
									const hasBodyCopy = section?.textTitle || section?.textContent
									if (!hasBodyCopy) {
										return null
									}
									return (
										<Item key={section._key} firstItem={index === 0} lastItem={index === sections.length - 1}>
											{section.textTitle && (<h6>{section.textTitle}</h6>)}
											{section.textContent && (<p>{section.textContent}</p>)}
										</Item>
									)

								default:
									return null
							}
						})}
					</DragContent>
				</DragContainer>
			</ThemeSelector>
		</Section>
	)
}

export default HorizontalDrag
