import React, { useRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import Slider from 'react-slick'
import { util, colors, animations } from 'styles'

const SwatchList = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
	h6 {
		width: 100%;
	}
	padding-bottom: 3px;
	${ util.responsiveStyles('padding-top', 30, 30, 26, 20) }
	> button {
		&:not(:first-of-type) {
			margin-left: 18px;
		}
	}
`

const ColorSwatch = styled.button<{ color: any, current?: boolean }>`
	appearance: none;
	border: none;
	outline: none;
	padding: 0;
	border-radius: 50%;
	position: relative;
	background: ${ ({ color }) => color || colors.sand };
	${ util.responsiveStyles('width', 56, 56, 52, 48) }
	${ util.responsiveStyles('height', 56, 56, 52, 48) }
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-shrink: 0;
	flex-grow: 0;
	&:hover {
		svg {
			opacity: 1;
		}
	}
	svg {
		transition: opacity ${ animations.mediumSpeed } ease-in-out;
		width: 20px;
		height: 20px;
		opacity: 0;
		* {
			fill: white;
		}
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: -3px;
		right: -3px;
		bottom: -3px;
		left: -3px;
		border: 1px solid transparent;
		border-radius: 50%;
		transition: border ${ animations.mediumSpeed } ease-in-out;
	}
	${ ({ current }) => current ? `
		&:after {
			border-color: ${ colors.gold };
		}
	` : `
		&:hover {
			&:after {
				border-color: ${ colors.gold };
			}
		}
	` }
`

interface slideData {
  [x: string]: any
}

type SlideshowProps = {
  className?: string,
  slide: slideData
}

const ColorSetSlider = ({
	className = '',
	slide
}: SlideshowProps) => {
	const slider = useRef(null)
	const [sliderEl, setSliderEl] = useState<any>(null)
	const [currentSlide, setCurrentSlide] = useState<number>(0)
	useEffect(() => {
		setSliderEl(slider)
	}, [])

	const goToSlide = (index: number) => {
		setCurrentSlide(index)
		sliderEl?.current?.slickGoTo(index)
	}

  const sliderSettings = {
    arrows: false,
    infinite: false,
    fade: true,
    variableWidth: false,
    centerMode: false,
    autoplay: false,
    swipe: true,
    speed: 500,
    adaptiveHeight: true
  }
	return (
		<div>
			<Slider {...sliderSettings} ref={slider}>
				{slide.colors.map((color: any) => (
					<Image image={color.asset} />
				))}
			</Slider>
			<SwatchList>
				{slide.colors.map((color: any, index: number) => (
					<ColorSwatch
						key={color.color._key}
						color={color.color.hex}
						current={currentSlide === index}
						onClick={() => goToSlide(index)}
					/>
				))}
			</SwatchList>
		</div>
	)
}

export default ColorSetSlider
