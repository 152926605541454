import colors from './colors'
import { lighten, darken } from 'polished'

// Themes (ThemeSelector Component)
export const themes = {
	default: {
		color: colors.textColor,
		background: colors.bgColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'lightGrey'
	},
	sand: {
		color: colors.textColor,
		background: colors.sand,
		buttonTheme: 'default',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.sand2
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		buttonTheme: 'default',
		buttonThemeSecondary: 'white',
		hrColor: colors.sand2
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		mainColor: colors.bgColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.cherry
	},
	blueberry: {
		color: colors.white,
		background: colors.blueberry,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.lagoon
	},
	gold: {
		color: colors.white,
		background: colors.gold,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.sand2
	},
	cherry: {
		color: colors.white,
		background: colors.cherry,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.cinnamon
	},
	cinnamon: {
		color: colors.white,
		background: colors.cinnamon,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.red
	},
	kale: {
		color: colors.white,
		background: colors.kale,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.pine
	},
	pine: {
		color: colors.white,
		background: colors.pine,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.kale
	},
	lagoon: {
		color: colors.white,
		background: colors.lagoon,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.blueberry
	},
	berry: {
		color: colors.white,
		background: colors.berry,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.plum
	},
	plum: {
		color: colors.white,
		background: colors.plum,
		mainColor: colors.white,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey',
		hrColor: colors.berry
	}
}

// Button Themes
export const buttonThemes = {
	default: {
		color: colors.white,
		background: colors.mainColor,
		hoverColor: colors.white,
		hoverBackground: colors.cinnamon
	},
	transparent: {
		color: colors.lightTextColor,
		background: 'transparent',
		hoverColor: colors.textColor,
		hoverBackground: 'transparent'
	},
	// textColor theme inherits the themes colors
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: lighten(0.07, colors.bgColor),
		hoverBackground: lighten(0.07, colors.textColor)
	},
	black: {
		color: colors.bgColor,
		background: colors.black,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	white: {
		color: colors.textColor,
		background: colors.white,
		hoverColor: colors.textColor,
		hoverBackground: colors.lightGrey
	},
	sand: {
		color: colors.textColor,
		background: colors.sand,
		hoverColor: colors.textColor,
		hoverBackground: colors.sand2
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		hoverBackground: darken(0.07, colors.lightGrey)
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
}

// Input Themes
export const inputThemes = {
	default: {
		color: colors.textColor,
		placeholderColor: colors.lightTextColor,
		background: colors.transparent,
		accentColor: colors.bgColor,
		hoverColor: colors.bgColor,
		borderColor: colors.lightTextColor,
		hoverBorderColor: colors.textColor,
		focusBorderColor: colors.mainColor,
		hoverBackground: colors.transparent,
		focusBackground: colors.transparent,
	}
}

export default themes
