import React from 'react'
import styled from '@emotion/styled'
import Media from 'components/Media'
import ThemeSelector from 'components/ThemeSelector'
import NoomPath from 'components/NoomPath'
import { useScrollPercentage } from 'react-scroll-percentage'
import { ReactComponent as ArrowDown } from 'assets/images/arrow-right-thick.svg'
import { mq, colors, typography } from 'styles'

const StyledMedia = styled(Media)<{ percentage: number, ref?: any, background?: string }>`
	--percentage: ${ ({ percentage = 0 }) => percentage };
	--start: 0%;
	--end: 20vw;
	border-radius: calc(var(--path-width) * 2 + 4px) 0 0 0;
	${ mq.mediumAndBelow } {
    border-radius: calc(var(--path-width) * 1.5) 0 0 0;
  }
	overflow: hidden;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transform-style: preserve3d;
	// transform: translate3d(0, 0, 1px);
	background: ${ ({ background }) => background};
	${ mq.largerAndUp } {
		top: calc(var(--col-width) * 2 + var(--site-margins));
	}
	.image-wrapper,
	.video-wrapper {
		margin-top: calc(var(--end) * -1);
		height: calc(100% + var(--end));
		will-change: transform;
		transform: translate3d(0, calc(var(--percentage) * (var(--end) - var(--start)) / (1 - 0) + var(--start)), 0);
		img,
		video {
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
`

const StyledThemeSelector = styled(ThemeSelector)`
	height: 100%;
	position: relative;
	${ mq.largerAndUp } {
		background: transparent;
	}
`

const MediaPath = styled(NoomPath)`
	color: ${ colors.offWhite };
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	bottom: 25%;
	width: 100%;
	transform: translate3d(0, 0, 0);
	${ mq.largerAndUp } {
		top: calc(var(--col-width) * 2 + var(--site-margins));
	}
	.child-content {
		top: 50%;
		left: 0;
		right: unset;
		margin-top: calc(var(--path-width) * -.5);
		display: flex;
		white-space: nowrap;
		justify-content: center;
		width: var(--path-width);
		transform: rotate(90deg);
		svg {
			flex-shrink: 0;
			flex-grow: 0;
			display: block;
			margin-top: 1px;
			margin-left: 3em;
			margin-right: 3em;
		}
		${ typography.smallCaps }
		${ mq.largerAndUp } {
			justify-content: flex-end;
			top: calc(100vh - (var(--col-width) * 2 + var(--site-margins)));
			margin-top: calc(var(--path-width) * -1);
		}
	}
`

interface sectionData {
  [x: string]: any
}

type HomeAtfProps = {
  media: object,
  image?: object,
  video?: object,
  setTheme: string
}

const AtfMedia = ({
	media,
	image,
	video,
	setTheme = 'blueberry'
}: HomeAtfProps) => {
	const [ref, percentage] = useScrollPercentage({ threshold: .5 })
	return (
		<div ref={ref}>
			<StyledThemeSelector setTheme={setTheme}>
				<MediaPath right={false}>
					Start Here <ArrowDown/>
				</MediaPath>
				<StyledMedia background={colors[setTheme]} media={media} percentage={percentage} image={image} video={video} />
			</StyledThemeSelector>
		</div>
	)
}

export default AtfMedia
