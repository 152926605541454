import React from 'react'
import styled from '@emotion/styled'
import { util } from 'styles'

const Wrapper = styled.div<{ className?: string, size?: string }>`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1em;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  ${ util.fontSmoothing }
  font-feature-settings: 'liga';
  color: inherit;
  // 24px is the Google prefered icon size
  font-size: ${ ({ size }) => size || '24px' };
`

type MaterialIconProps = {
  className?: string,
  children: any,
  size?: string
}

const MaterialIcon = ({ className = '', children, size }: MaterialIconProps) => {
  if (!children) {
    return null
  }
  return (
    <Wrapper className={'icon material-icon ' + className} size={size}>{children}</Wrapper>
  )
}

export default MaterialIcon