import React from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import Video from 'components/Video'

const Wrapper = styled.div<{ className?: string }>``

interface mediaData {
  mediaType?: 'image' | 'video',
  image?: any,
  video?: any
}

type MediaProps = {
  className?: string,
  media: mediaData,
  video?: object,
  image?: object
}

const Media = ({ className = '', media, video, image }: MediaProps) => {
  if (media?.mediaType === 'image' && media?.image) {
    return (
      <Wrapper className={'media-wrapper ' + className}>
        <Image image={media?.image} {...image}/>
      </Wrapper>
    )  
  } else if (media?.mediaType === 'video') {
    return (
      <Wrapper className={'media-wrapper ' + className}>
        <Video video={media?.video} {...video}/>
      </Wrapper>
    )
  } else {
    return <></>
  }
}

export default Media