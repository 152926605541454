import React from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import Section from 'components/Section'
import { colors, util } from 'styles'

const Wrapper = styled(Grid)``

const Card = styled.div`
	background: ${ colors.white };
	${ util.responsiveStyles('padding', 50, 40, 40, 30) }
	h4, p {
		margin: 0;
	}
`

interface cardData {
  [x: string]: any
}

type WideCardsProps = {
  className?: string,
  cards: Array<cardData>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const WideCards = ({
	className = '',
	cards,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: WideCardsProps) => {
	if (cards?.length < 1) {
		return null
	}
	
	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<Wrapper
				className={className}
				small='[1]'
				medium='1 [12] 1'
				colGap='var(--site-gutters)'
				rowGap='var(--site-gutters)'
				margins
			>
				{cards?.map((card, index) => {
					return (
						<Card>
							<Grid small='[1]' large='[1] [1]' rowGap='var(--site-gutters)'>
								<div><h4>{card.title}</h4></div>
								<div><p>{card.text}</p></div>
							</Grid>
						</Card>
					)
				})}
			</Wrapper>
		</Section>
	)
}

export default WideCards
