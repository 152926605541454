import React, { useRef, useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import Section from 'components/Section'
import Image from 'components/Image'
import Video from 'components/Video'
import Button from 'components/Button'
import ColorSetSlider from './ColorSetSlider'
import Slider from 'react-slick'
import { MdCheck } from 'react-icons/md'
import { ReactComponent as IconClipboard } from 'assets/images/icon-clipboard.svg'
import { util, colors, animations, mq } from 'styles'

const SwatchList = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
	h6 {
		width: 100%;
	}
	${ util.responsiveStyles('padding-top', 20, 18, 16, 12) }
	${ util.responsiveStyles('padding-top', 30, 30, 26, 20) }
	> div {
		&:not(:first-of-type) {
			margin-left: 18px;
		}
	}
`

const ColorSwatch = styled.div<{ color: any }>`
	border-radius: 50%;
	position: relative;
	background: ${ ({ color }) => color || colors.sand };
	${ util.responsiveStyles('width', 56, 56, 52, 48) }
	${ util.responsiveStyles('height', 56, 56, 52, 48) }
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-shrink: 0;
	flex-grow: 0;
	&:hover {
		.icon:first-of-type {
			opacity: 1;
		}
	}
	.icon {
		transition: opacity ${ animations.mediumSpeed } ease-in-out,
								transform ${ animations.mediumSpeed } ease-in-out;
		opacity: 0;
		&:first-of-type {
			&.copied {
				transform: translate3d(0, -100%, 0);
				opacity: 0;
			}
		}
		&:nth-of-type(2) {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -10px;
			transform: translate3d(0, 100%, 0);
			opacity: 0;
			&.copied {
				transform: none;
				opacity: 1;
			}
		}
	}
	svg {
		width: 20px;
		height: 20px;
		display: block;
		// opacity: 0;
		* {
			&:not([fill='none']) {
				fill: white;
			}
		}
	}
`

const Slide = styled.div<{ firstItem?: boolean, lastItem?: boolean }>``

const SlideshowNav = styled.div`
	margin-bottom: 30px;
	overflow-x: auto;
	white-space: nowrap;
`

const NavButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: var(--site-margins);
	${ mq.mediumAndUp } {
		padding-left: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
	}
	.button-wrap {
		flex-shrink: 0;
		&:not(:last-of-type) {
			margin-right: 10px;
		}
		&:last-of-type {
			margin-right: 10px;
			padding-right: var(--site-margins);
			${ mq.mediumAndUp } {
				padding-right: calc(var(--site-margins) + var(--site-gutters) + var(--col-width));
			}
		}	
	}
`

interface slideData {
  [x: string]: any
}

type SlideshowProps = {
  className?: string,
  slides: Array<slideData>,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const Slideshow = ({
	className = '',
	slides,
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: SlideshowProps) => {
	const slider = useRef(null)
	const [sliderEl, setSliderEl] = useState<any>(null)
	const [currentSlide, setCurrentSlide] = useState<number>(0)
	const [copied, setCopied] = useState('')
	
	useEffect(() => {
		setSliderEl(slider)
	}, [])

  const sliderSettings = {
    arrows: false,
    infinite: false,
    variableWidth: false,
    centerMode: false,
    autoplay: false,
    swipe: true,
    speed: 500,
    adaptiveHeight: true,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex)
  }

  const copyColorCode = (key: string) => {
		setCopied(key)
		setTimeout(() => {
			setCopied('')
		}, 3000)
	}

	return (
		<Section
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			isFirstSection={isFirstSection}
			isLastSection={isLastSection}
		>
			<SlideshowNav>
				<NavButtons>
					{slides.map((slide: any, index: number) => {
						const current = currentSlide === index
						return (
							<div className='button-wrap' key={slide._key}>
								<Button
									size='small'
									setTheme={current ? 'white' : 'sand'}
									onClick={() => sliderEl.current.slickGoTo(index)}
									disabled={current}
								>
									{slide.slideTitle}
								</Button>
							</div>
						)
					})}
				</NavButtons>
			</SlideshowNav>
			<Slider {...sliderSettings} ref={slider}>
				{slides.map((slide: any, index: number) => {
					switch (slide._type) {
						case ('image'):
							return (
								<Grid small='[1]' medium='1 [12] 1' margins colGap='var(--site-gutters)'>
									<Slide key={slide._key} firstItem={index === 0} lastItem={index === slides.length - 1}>
										<Image image={slide.asset} />
									</Slide>
								</Grid>
							)

						case ('video'):
							return (
								<Grid small='[1]' medium='1 [12] 1' margins colGap='var(--site-gutters)'>
									<Slide key={slide._key} firstItem={index === 0} lastItem={index === slides.length - 1}>
										<Video video={slide.videoFile} />
									</Slide>
								</Grid>
							)

						case ('colorPalette'):
							return (
								<Grid small='[1]' medium='1 [12] 1' margins colGap='var(--site-gutters)'>
									<Slide key={slide._key} firstItem={index === 0} lastItem={index === slides.length - 1}>
										<Image image={slide.asset} />
										<SwatchList>
											<h6>Colors</h6>
											{slide.colors.map((color: any) => {
												if (!color?.hex) {
													return false
												}
												return (
													<CopyToClipboard text={color.hex}>
														<ColorSwatch key={color._key} color={color.hex} onClick={() => copyColorCode(color._key)}>
															<span className={copied === color._key ? 'icon copied' : 'icon'}><IconClipboard /></span>
															<span className={copied === color._key ? 'icon copied' : 'icon'}><MdCheck size='24px' /></span>
														</ColorSwatch>
													</CopyToClipboard>
												)
											})}
										</SwatchList>
									</Slide>
								</Grid>
							)

						case ('colorSets'):
							return (
								<Grid small='[1]' medium='1 [12] 1' margins colGap='var(--site-gutters)'>
									<Slide key={slide._key} firstItem={index === 0} lastItem={index === slides.length - 1}>
										<ColorSetSlider slide={slide}/>
									</Slide>
								</Grid>
							)

						default:
							return null
					}
				})}
			</Slider>
		</Section>
	)
}

export default Slideshow
