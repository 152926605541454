import React, { Fragment } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { mq } from 'styles'

const widthToRenderer = (
  winWidth?: any,
  small?: any,
  medium?: any,
  large?: any,
  larger?: any,
  extraLarge?: any,
  custom?: any
) => {
  if (custom?.breakpoint && winWidth >= custom?.breakpoint) {
    // For custom breakpoint if necessary
    return custom.content
  } else if (winWidth >= mq.extraLargeBreakpoint) {
    return extraLarge || larger || large || medium || small
  } else if (winWidth >= mq.largerBreakpoint) {
    return larger || large || medium || small
  } else if (winWidth > mq.largeBreakpoint) {
    return large || medium || small
  } else if (winWidth >= mq.mediumBreakpoint) {
    return medium || small
  } else if (winWidth < mq.mediumBreakpoint) {
    return small
  }
}

type ResponsiveComponentProps = {
  small: any,
  medium?: any,
  large?: any,
  larger?: any,
  extraLarge?: any,
  custom?: any
}

const ResponsiveComponent = ({
  small,
  medium,
  large,
  larger,
  extraLarge,
  custom
}: ResponsiveComponentProps) => {
  const winWidth = useWindowWidth()
  return (
    <Fragment>
      {widthToRenderer(winWidth, small, medium, large, larger, extraLarge, custom)}
    </Fragment>
  )
}

export default ResponsiveComponent
