import React from 'react'
import styled from '@emotion/styled'
import Grid from 'components/Grid'
import Section from 'components/Section'
import { colors, typography, util, mq } from 'styles'

const Wrapper = styled(Grid)``

const Card = styled.div`
	background: ${ colors.white };
	${ util.responsiveStyles('padding', 50, 40, 40, 30) }
`

const TypeRow = styled.div<{ firstItem?: boolean, lastItem?: boolean }>`
	${ mq.largeAndUp } {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	${ ({ firstItem }) => !firstItem ? `
		border-top: 1px solid ${ colors.sand };
		${ util.responsiveStyles('padding-top', 40, 30, 14, 8) }
	` : '' }
	${ ({ lastItem }) => !lastItem ? `
		${ util.responsiveStyles('padding-bottom', 40, 30, 14, 8) }
	` : '' }
`

const RowStats = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	${ typography.bodyTiny }
	width: 10em;
	margin-bottom: 20px;
	${ mq.largeAndUp } {
		margin-bottom: 0;
	}
`

const RowPreview = styled.div<{ type?: string }>`
	flex-grow: 1;
	${ ({ type }) => `
		${ type === 'display' ? `
			${ typography.h1 }
			${ util.responsiveStyles('font-size', 144, 144, 100, 64) }
		` : '' }
		${ type === 'header1' ? `
			${ typography.h1 }
		` : '' }
		${ type === 'header2' ? `
			${ typography.h3 }
		` : '' }
		${ type === 'header3' ? `
			${ typography.h4 }
		` : '' }
		${ type === 'body' ? `
			${ typography.body }
		` : '' }
		${ type === 'detail' ? `
			${ typography.h6 }
		` : '' }
		${ type === 'detailSmall' ? `
			${ typography.smallCaps }
		` : '' }
	` }
`

type TypographyHierarchyProps = {
  className?: string,
  nextTheme?: string,
	prevTheme?: string,
	isFirstSection?: boolean,
	isLastSection?: boolean
}

const rows = [
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'display',
		previewText: 'This is Display'
	},
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'header1',
		previewText: 'This is a Header 1'
	},
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'header2',
		previewText: 'This is Header 2'
	},
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'header3',
		previewText: 'This is Header 3'
	},
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'body',
		previewText: 'This is what you write body copy.'
	},
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'detail',
		previewText: 'This is for small details'
	},
	{
		title: 'Untitled Serif',
		size: '250% of H1',
		spacing: 'Spacing',
		type: 'detailSmall',
		previewText: 'and this is for even smaller details'
	}
]

const TypographyHierarchy = ({
	className = '',
	nextTheme,
	prevTheme,
	isFirstSection,
	isLastSection
}: TypographyHierarchyProps) => (
	<Section
		nextTheme={nextTheme}
		prevTheme={prevTheme}
		isFirstSection={isFirstSection}
		isLastSection={isLastSection}
	>
		<Wrapper
			className={className}
			small='[1]'
			medium='1 [12] 1'
			colGap='var(--site-gutters)'
			margins
		>
			<div>
				<Card>
					{rows.map((row, index) => (
						<TypeRow firstItem={index === 0} lastItem={index === rows.length - 1}>
							<RowStats>
								{row.title}<br/>
								{row.size}<br/>
								{row.spacing}<br/>
							</RowStats>
							<RowPreview
								type={row.type}
							>
								{row.previewText}
							</RowPreview>
						</TypeRow>
					))}
				</Card>
			</div>
		</Wrapper>
	</Section>
)

export default TypographyHierarchy
