import React from 'react'
import styled from '@emotion/styled'
import { typography, colors } from 'styles'

const Wrapper = styled.div`
	width: 100%;
	display: block;
	margin: 0 0 calc(var(--vertical-spacing) * .5) 0;
	hr {
		margin: 0 0 calc(var(--vertical-spacing) * .5) 0;
		border-color: ${ colors.gold };
	}
`

const Headline = styled.div`
	${ typography.smallCaps }
	padding-left: 24px;
	position: relative;
	color: ${ colors.gold };
	&:before {
		content: '';
		display: block;
		width: 10px;
		height: 10px;
		background: ${ colors.red };
		position: absolute;
		top: .215em;
		left: 0;
		transform: rotate(45deg);
	}
`

type StarHeadlineProps = {
	border?: boolean,
	children?: any,
	className?: string,
	style?: any
}

const StarHeadline = ({ className = '', children, border, style = {} }: StarHeadlineProps) => {
	return (
		<Wrapper style={style}>
			{border && <hr/>}
			<Headline>{children}</Headline>
		</Wrapper>
	)
}

export default StarHeadline